import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon from "./Icon";

const NoticeWrapper = styled.div`
    position: relative;
    display: ${(props) => (props.isVisible ? "table" : "none")};
    opacity: 1;
    margin: 1.4285714286em 0;
    padding: 1em;
    border-radius: 4px;
    border: 1px solid #d3e7f5;
    background-color: #eff8ff;
    color: #545454;
    transition: opacity 0.5s ease-in-out;

    &.notice-error {
        border-color: #fad9d9;
        background-color: #ffebeb;
    }

    &.notice-error > svg {
        color: #e32c2b;
    }

    &.notice-warning {
        border-color: #f0e4bc;
        background-color: #fff8de;
    }

    &.notice-warning > div > a {
        border-color: #f0e4bc;
    }

    &.notice-warning > svg {
        color: #b88600;
    }

    &.cta > div {
        padding-bottom: 1.1428571429em;
    }

    &.cta > div > p {
        padding-bottom: 1em;
    }

    & > svg {
        color: #197bbd;
        margin-top: -0.1428571429em;
        margin-right: 0.7142857143em;
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        fill: currentColor;
    }
`;

const NoticeContent = styled.div`
    display: table-cell;
    width: 100%;
    padding-right: 1.1428571429em;

    & > a {
        padding: 1em;
        border-radius: 4px;
        border: 1px solid #d3e7f5;
    }
`;

const noticeType = {
    ERROR: "notice-error",
    WARNING: "notice-warning",
    INFO: "notice-info",
};

export default function Notice({ className, content, isVisible, type }) {
    return (
        <NoticeWrapper
            isVisible={isVisible}
            className={
                className
                    ? `${noticeType[type]} ${className}`
                    : noticeType[type]
            }
        >
            <Icon icon="exclamation" aria-hidden focusable={false} />
            <NoticeContent>{content}</NoticeContent>
        </NoticeWrapper>
    );
}

Notice.propTypes = {
    className: PropTypes.string,
    content: PropTypes.element,
    isVisible: PropTypes.bool,
    type: PropTypes.string,
};

Notice.defaultProps = {
    className: undefined,
    content: "",
    isVisible: false,
    type: "INFO",
};
