import React from "react";
import PropTypes from "prop-types";
// import Icon from "../components/Icon";

import { numberWithCommas } from "../utils";

export default function TotalsTable({
    currency,
    difAmount,
    paidAmount,
    shipping,
    step,
    taxes,
    total,
    _t,
}) {
    const isAdditionalPayment = difAmount > 0 && paidAmount > 0;

    return (
        <table className="total-line-table">
            <tbody className="total-line-table_tbody">
                <tr className="total-line subtotal">
                    <th className="total-line_name">{_t("subtotal")}</th>
                    <td className="total-line_price">
                        <span className="order-summary_emphasis">
                            ${numberWithCommas(total)}
                        </span>
                    </td>
                </tr>
                <tr className="total-line shipping">
                    <th className="total-line_name">
                        <span>{_t("ship")}</span>
                        {/* <a href="#">
							<Icon icon="question" />
						</a>   */}
                    </th>
                    <td className="total-line_price">
                        <span className="order-summary_small-text">
                            {step === "checkout"
                                ? _t("calculatedUpNext")
                                : `$${shipping}`}
                        </span>
                    </td>
                </tr>
                <tr className="total-line taxes">
                    <th className="total-line_name">{_t("estimatedTaxes")}</th>
                    <td className="total-line_price">
                        <span className="order-summary_emphasis">
                            ${numberWithCommas(taxes)}
                        </span>
                    </td>
                </tr>
            </tbody>
            {isAdditionalPayment && (
                <tbody className="total-line-table_tbody">
                    <tr className="total-line total">
                        <th className="total-line_name tbody-separator">
                            {_t("total")}
                        </th>
                        <td className="total-line_price tbody-separator">
                            <span className="order-summary_emphasis">
                                ${numberWithCommas(total)}
                            </span>
                        </td>
                    </tr>
                    <tr className="total-line paid">
                        <th className="total-line_name">{_t("alreadyPaid")}</th>
                        <td className="total-line_price">
                            <span className="order-summary_emphasis">
                                ${numberWithCommas(paidAmount)}
                            </span>
                        </td>
                    </tr>
                </tbody>
            )}
            <tfoot className="total-line-table_footer">
                <tr className="total-line">
                    <th className="total-line_name">
                        <span className="payment-due-label_total">
                            {isAdditionalPayment
                                ? _t("amountToPay")
                                : _t("total")}
                        </span>
                    </th>
                    <td className="total-line_price">
                        <span className="payment-due_currency">{currency}</span>
                        <span className="payment-due_price">
                            $
                            {isAdditionalPayment
                                ? numberWithCommas(difAmount)
                                : numberWithCommas(total + taxes + shipping)}
                        </span>
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

TotalsTable.propTypes = {
    currency: PropTypes.string,
    difAmount: PropTypes.number,
    paidAmount: PropTypes.number,
    shipping: PropTypes.number,
    step: PropTypes.string,
    taxes: PropTypes.number,
    total: PropTypes.number,
    _t: PropTypes.func.isRequired,
};

TotalsTable.defaultProps = {
    currency: "USD",
    difAmount: 0,
    paidAmount: 0,
    taxes: 0,
    total: 0,
    shipping: 0,
    step: "checkout",
};
