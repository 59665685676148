import React from "react";
import styled from "styled-components";
// import Icon from "./Icon";
// import renderIf from "../utils/renderIf";

const InputWrapper = styled.div`
    position: relative;
    border: 0;
    /* margin-bottom: ${(props) => props.marginBottom || "10px"}; */
    /* margin-right: 10px; */
    min-width: 100px;
    input {
        z-index: 2;
        padding: 1.96rem 0.875rem 0.825rem;
        border: 1px solid
            ${(props) =>
                !props.valid ? props.theme.error : props.theme.inputBorder};
        border-radius: 3px;
        width: 100%;
        height: 100%;
        /* padding-right: 2.8rem; */
        cursor: pointer;
        background-color: transparent;
        /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: ${(props) => props.theme.inputBg};
        font-size: 15px;
        color: ${props => props.theme.primaryDark};
    }
    label {
        position: absolute;
        top: 0.4rem;
        font-size: 1.12rem;
        left: 0.825rem;
        z-index: 2;
        pointer-events: none;
        opacity: 0.6;
        transition: 0.12s;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        color: 1px solid ${(props) => (!props.valid ? props.theme.red : "")};
    }
    span {
        color: #4d4d4d;
        text-decoration: none;
        transition: color 0.1s ease;
        svg {
            position: absolute;
            top: 50%;
            right: 0.625rem;
            margin-top: -5px;
            pointer-events: none;
        }
    }
`;

const Input = ({
    value,
    name,
    onChange,
    onFocus,
    label,
    placeholder,
    type,
    index,
    valid,
    autoCapitalize,
    spellCheck,
    autoComplete,
    ariaDescribedby,
    marginBottom,
    maxLength,
    pattern,
}) => {
    return (
        <InputWrapper valid={valid} marginBottom={marginBottom}>
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                name={name}
                autoCapitalize={autoCapitalize ? autoCapitalize : "off"}
                spellCheck={spellCheck}
                autoComplete={autoComplete}
                id={name}
                data-index={index}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                aria-describedby={name || ariaDescribedby}
                placeholder={placeholder}
                maxLength={maxLength}
                pattern={pattern}
            />
        </InputWrapper>
    );
};

export default Input;
