import React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import Helmet from "react-helmet";
import styled from "styled-components";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Row, Title, Subtitle } from "../../sdk/Layout";
import { Button, Buttons } from "../../sdk/Button";
import { Control, Field, FieldBody, SelectBox } from "../../sdk/Form";
import Icon from "../../components/Icons2";
import validate from "../../utils/validator";
import Blueberry from "../../../blueberry";
import useNotification from "../../hooks/useNotification";

const ButtonGroup = styled(Buttons)`
    padding-top: 20px;
    text-align: right;
`;
const ControlExtended = styled(Control)`
    .PhoneInput {
        display: flex;
        align-items: center;
    }

    .PhoneInputInput {
        /* The phone number input stretches to fill all empty space */
        flex: 1;
        /* The phone number input should shrink
	   to make room for the extension input */
        min-width: 0;
    }

    .PhoneInputCountryIcon {
        width: calc(1em * 1.5);
        height: 1em;
    }

    .PhoneInputCountryIcon--square {
        width: 1em;
    }

    .PhoneInputCountryIcon--border {
        background-color: rgba(0, 0, 0, 0.1);

        box-shadow: 0 0 0 var(1px) rgba(0, 0, 0, 0.5),
            inset 0 0 0 var(1px) rgba(0, 0, 0, 0.5);
    }

    .PhoneInputCountryIconImg {
        display: block;
        max-width: 50px;
        width: 100%;
        height: 100%;
    }

    .PhoneInputInternationalIconPhone {
        opacity: 0.8;
    }

    .PhoneInputInternationalIconGlobe {
        opacity: 0.65;
    }

    .PhoneInputCountry {
        position: relative;
        align-self: stretch;
        display: flex;
        align-items: center;
        margin-right: 0.35em;
    }

    .PhoneInputCountrySelect {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0;
        opacity: 0;
        cursor: pointer;
    }

    .PhoneInputCountrySelect[disabled] {
        cursor: default;
    }

    .PhoneInputCountrySelectArrow {
        display: block;
        content: "";
        width: 0.3em;
        height: 0.3em;
        margin-left: 0.35em;
        border-style: solid;
        border-color: inherit;
        border-top-width: 0;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 1px;
        transform: rotate(45deg);
        opacity: 0.45;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        + .PhoneInputCountrySelectArrow {
        opacity: 1;
        color: inherit;
    }

    .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
        box-shadow: 0 0 0 var(1px) #03b2cb, inset 0 0 0 var(1px) #03b2cb;
    }

    .PhoneInputCountrySelect:focus
        + .PhoneInputCountryIcon
        .PhoneInputInternationalIconGlobe {
        opacity: 1;
        color: #03b2cb;
    }
`;

function Perfil({ user, _t }) {
    const [isFormEdited, setFormEdited] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    const [handleNotify, NotifyProfile] = useNotification({
        timeout: 2000,
    });

    const [formControl, setFormControl] = React.useState({
        firstName: {
            value: user && user.profile ? user.profile.firstName : "",
            valid:
                user && user.profile && user.profile.firstName ? true : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        lastName: {
            value: user && user.profile ? user.profile.lastName : "",
            valid: user && user.profile && user.profile.lastName ? true : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        contactPhoneNumber: {
            value: user && user.profile ? user.profile.contactPhoneNumber : "",
            valid:
                user && user.profile && user.profile.contactPhoneNumber
                    ? true
                    : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        email: {
            value: user && user.emails ? user.emails[0].address : "",
            valid: true,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
                isEmail: true,
            },
        },
        region: {
            value: user && user.profile ? user.profile.region : "",
            valid: false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        country: {
            value: user && user.profile ? user.profile.country : "",
            valid: user && user.profile && user.profile.country ? true : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        facebook: {
            value: user && user.profile ? user.profile.facebook : "",
            valid: true,
            touched: false,
            validationRules: {
                minLength: 0,
                isRequired: false,
            },
        },
        instagram: {
            value: user && user.profile ? user.profile.instagram : "",
            valid: true,
            touched: false,
            validationRules: {
                minLength: 0,
                isRequired: false,
            },
        },
        linkedin: {
            value: user && user.profile ? user.profile.linkedin : "",
            valid: true,
            touched: false,
            validationRules: {
                minLength: 0,
                isRequired: false,
            },
        },
        twitter: {
            value: user && user.profile ? user.profile.twitter : "",
            valid: true,
            touched: false,
            validationRules: {
                minLength: 0,
                isRequired: false,
            },
        },
    });

    const onUpdateProfile = () => {
        let formData = {};
        let hasErrors = false;
        const updatedFormElement = { ...formControl };

        for (let formElementId in formControl) {
            if (!formControl[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControl[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (!isValidPhoneNumber(updatedFormElement.contactPhoneNumber.value)) {
            handleNotify({
                paragraph: `Check ${_t("contactPhoneNumber")}`,
                error: true,
            });
            return;
        }

        Blueberry.profileUpdate(formData).then((resp) => {
            setIsLoading(false);
            handleNotify({
                paragraph: _t("profileUpdated"),
                info: true,
            });
        });
    };

    const setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...formControl,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;
        setFormEdited(false);
        setFormControl(updatedControls);
    };

    const validateForm = (formElement) => {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    };

    return (
        <>
            <Helmet>
                <title>{_t("myAccount")} - Noritex</title>
            </Helmet>
            <NotifyProfile></NotifyProfile>
            <Subtitle as="h2" size="5">
                <Icon icon="user" />
                {_t("profile")}
            </Subtitle>

            <Row>
                <Title as="h3" size="6">
                    {_t("personalDetails")}
                </Title>
                <Field isHorizontal>
                    <FieldBody>
                        <Field>
                            <label>{_t("firstName")}</label>
                            <Control isExpanded>
                                <input
                                    type="text"
                                    name="firstName"
                                    onChange={setValue}
                                    className={validateForm(
                                        formControl.firstName
                                    )}
                                    value={formControl.firstName.value}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <label>{_t("lastName")}</label>
                            <Control isExpanded>
                                <input
                                    type="text"
                                    name="lastName"
                                    onChange={setValue}
                                    className={validateForm(
                                        formControl.lastName
                                    )}
                                    value={formControl.lastName.value}
                                />
                            </Control>
                        </Field>
                    </FieldBody>
                </Field>

                <Field isHorizontal>
                    <FieldBody>
                        <Field>
                            <label>{_t("contactPhoneNumber")}</label>
                            <ControlExtended isExpanded>
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={formControl.contactPhoneNumber.value}
                                    className={validateForm(
                                        formControl.contactPhoneNumber
                                    )}
                                    onChange={(val) =>
                                        setValue({
                                            target: {
                                                value: val ? val : "",
                                                name: "contactPhoneNumber",
                                            },
                                        })
                                    }
                                />
                            </ControlExtended>
                        </Field>
                        <Field>
                            <label>Email</label>
                            <Control isExpanded>
                                <input
                                    type="email"
                                    name="email"
                                    disabled
                                    onChange={setValue}
                                    className={validateForm(formControl.email)}
                                    value={formControl.email.value}
                                />
                            </Control>
                        </Field>
                    </FieldBody>
                </Field>
            </Row>

            <Row>
                <Title as="h3" size="6">
                    {_t("direction")}
                </Title>
                <Field>
                    <label>{_t("country")}</label>
                    <SelectBox isExpanded>
                        <CountryDropdown
                            name="country"
                            onChange={(val) =>
                                setValue({
                                    target: { value: val, name: "country" },
                                })
                            }
                            className={validateForm(formControl.country)}
                            value={formControl.country.value}
                        />
                    </SelectBox>
                </Field>

                <Field>
                    <label>{_t("region")}</label>
                    <SelectBox isExpanded>
                        <RegionDropdown
                            name="region"
                            onChange={(val) =>
                                setValue({
                                    target: { value: val, name: "region" },
                                })
                            }
                            className={validateForm(formControl.country)}
                            country={formControl.country.value}
                            value={formControl.region.value}
                        />
                    </SelectBox>
                </Field>
            </Row>

            <Row>
                <Title as="h3" size="6">
                    {_t("socialAccounts")}
                </Title>
                <Field hasAddons>
                    <Control>
                        <Button static>
                            <Icon icon="facebook" />
                        </Button>
                    </Control>
                    <Control>
                        <input
                            type="text"
                            placeholder="Facebook"
                            name="facebook"
                            onChange={setValue}
                            value={formControl.facebook.value}
                            className={validateForm(formControl.facebook)}
                        />
                    </Control>
                </Field>

                <Field hasAddons>
                    <Control>
                        <Button static>
                            <Icon icon="twitter" />
                        </Button>
                    </Control>
                    <Control>
                        <input
                            type="text"
                            placeholder="Twitter"
                            name="twitter"
                            onChange={setValue}
                            value={formControl.twitter.value}
                            className={validateForm(formControl.twitter)}
                        />
                    </Control>
                </Field>

                <Field hasAddons>
                    <Control>
                        <Button static>
                            <Icon icon="linkedin" />
                        </Button>
                    </Control>
                    <Control>
                        <input
                            type="text"
                            placeholder="Linkedin"
                            name="linkedin"
                            onChange={setValue}
                            value={formControl.linkedin.value}
                            className={validateForm(formControl.linkedin)}
                        />
                    </Control>
                </Field>

                <Field hasAddons>
                    <Control>
                        <Button static>
                            <Icon icon="instagram" />
                        </Button>
                    </Control>
                    <Control>
                        <input
                            type="text"
                            placeholder="Instagram"
                            name="instagram"
                            onChange={setValue}
                            value={formControl.instagram.value}
                            className={validateForm(formControl.instagram)}
                        />
                    </Control>
                </Field>

                <ButtonGroup>
                    <Button
                        onClick={onUpdateProfile}
                        primary
                        loading={isLoading ? isLoading : undefined}
                        static={isFormEdited}
                    >
                        {_t("saveChanges")}
                    </Button>
                </ButtonGroup>
            </Row>
        </>
    );
}

export default Perfil;
