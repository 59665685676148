import worker from "../../../blueberry/worker";
import WebWorker from "../../../blueberry/WebWorker";

export function doCalculations() {
    // const self = ref;
    const { items } = this.props.store.cart;

    // initiate the webworker
    this.worker = new WebWorker(worker);
    // post if items are in cart
    if (items) this.worker.postMessage({ do: "total", items });
    // if (this.props.stats.cart) this.worker.postMessage({do: "stats", data: this.props.stats.cart});

    // list to worker changes
    this.worker.onmessage = (event) => {
        const { data } = event.data;
        // set the calculations to the state when there is a change
        switch (event.data.do) {
            case "total":
                this.setState({
                    calculations: data,
                });
                break;
            default:
                break;
        }
    };
}

export function redirectToCart() {
    const { lang } = this.props.match.params;
    return this.props.history.replace(`/${lang}/cart/`);
}
