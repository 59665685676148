import React from "react";
import { Helmet } from "react-helmet";
import Dropzone from "react-dropzone-uploader";
import MainTemplate from "../templates/MainTemplate";
// import withSSR from '../utils/withSSR';
import withTracker from "../../blueberry/connectPromise";
import validate from "../utils/validator";
import Blueberry from "../../blueberry/index";

import Icon from "../components/Icon";
import Listener from "../components/Listener";

import { getParameterByName } from "../utils";
import NewClaimForm from "./NewClaim";
import { config } from "../../config";

// import Blueberry from "../../blueberry/index";
// import withTracker from "../../blueberry/connect";

// import "react-dropzone-uploader/dist/styles.css";

const getNewGridRow = (i) => ({
    sku: "",
    description: "",
    quantity: "",
    price: "",
    images: [],
});

class ClaimForm extends MainTemplate {
	constructor(props) {
        super(props);

        const order = getParameterByName("order", props.location.search);
        const sku = getParameterByName("sku", props.location.search);
        const quantity = getParameterByName("quantity", props.location.search);
        const hasTransfer = getParameterByName("hasTransfer", props.location.search) === "true";
        const companyId = getParameterByName("companyId", props.location.search);
        const claimId = getParameterByName("claimId", props.location.search);

        const { user } = this.props.store;

        const rows = [];
        const initialFiles = [];

        let transfers = [];

        if (hasTransfer) {
            let slugs = localStorage.getItem("items-transfer-claim");

            transfers = slugs ? JSON.parse(slugs) : [];
        }

		for (let i = 0; i < transfers.length; i++) {
			if (claimId) break;
			rows.push({
				...getNewGridRow(i),
				sku: transfers && transfers[i] ? transfers[i].sku : "",
				quantity: transfers && transfers[i] ? transfers[i].quantity : "",
				title: transfers && transfers[i] ? transfers[i].title : "",
				thumb: transfers && transfers[i] ? transfers[i].thumb : "",
				price: transfers && transfers[i] ? transfers[i].price : "",
				multiple: transfers && transfers[i] ? transfers[i].multiple : "",
			});
		}

        let formControls = {
            firstName: {
                value: user && user.profile ? user.profile.firstName : "",
                valid: user && user.profile ? true : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            lastName: {
                value: user && user.profile ? user.profile.lastName : "",
                valid: user && user.profile ? true : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            company: {
                value:
                    user && user.profile && user.profile.companyName
                        ? user.profile.companyName
                        : "",
                valid:
                    user && user.profile && user.profile.companyName
                        ? true
                        : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            clientNumber: {
                value: user && user.clientNumber ? user.clientNumber : "",
                valid: user && user.clientNumber ? true : false,
                touched: false,
                validationRules: {
                    minLength: 5,
                    isRequired: true,
                },
            },
            salesrepId: {
                value: user && user.salesrepId ? user.salesrepId : "",
                valid: user && user.salesrepId ? true : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            contactPhoneNumber: {
                value:
                    user && user.profile && user.profile.contactPhoneNumber
                        ? user.profile.contactPhoneNumber
                        : "",
                valid:
                    user && user.profile && user.profile.contactPhoneNumber
                        ? true
                        : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            email: {
                value:
                    user && user.emails && user.emails.length > 0
                        ? user.emails[0].address
                        : "",
                valid:
                    user && user.emails && user.emails.length > 0
                        ? true
                        : false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                    isEmail: true,
                },
            },
        };

        // Go to step depends of brand or edit claim
        let step = props.match.params.brandSlug || config.IS_NORITEX ? 1 : 0;
        if (claimId) step = 3;

        this.state = {
            salesreps: [],
            steps: step,
            images: [],
            isModalVisible: false,
            grid: [...rows],
            formControls,
            order: order,
            urlParams: `claim?companyId=${companyId}&order=${order}&hasTransfer=true`,
            claimId: claimId,
            userIsReady: false,
        };
    }

    getSalesreps = () => {
        Blueberry.salesreps({ brandSlug: this.props.match.params.brandSlug })
            .then((salesreps) => {
                this.setState({ salesreps });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    componentDidMount() {
        this.getSalesreps();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.brandSlug !==
            this.props.match.params.brandSlug
        ) {
            this.setState({
                steps:
                    this.props.match.params.brandSlug != null ||
                    config.IS_NORITEX
                        ? 1
                        : 0,
            });
            this.getSalesreps();
        }

        // consultar detalle del reclamo cuando user is ready
        const { user } = this.props.store;
        const claimId = getParameterByName("claimId", this.props.location.search);
        if (user.id && claimId && !this.state.userIsReady) {
            Blueberry.getClaimDetailsFromUserToEdit({ claimId })
                .then((resp) => {
                    const rows = [], initialFiles = [];
                    // load the grid
                    for (let i = 0; i < resp[0].issues.length; i++) {
                        const issue = resp[0].issues[i];
                        let files = {};
                        // initial files uploaded if edit
                        issue.description.map(desc => {
                            files = {...files, [desc.name]: [...desc.images]};
                        })

						const rowDescription = issue.description.map((desc) => ({
							name: desc.name,
							checked: desc.checked,
							value: desc.value,
							images: desc.images,
							comments: desc.comments,
						}));

                        rows.push({
                            sku: issue.sku,
							description: rowDescription,
                            quantity: issue.invoiced,
                            images: issue.images,
                            title: issue.title,
                            thumb: issue.thumb,
                            price: issue.price,
                            multiple: issue.multiple ?? "",
                            initialFiles: files,
                        });
                    }
                    this.setState({ grid: [...rows], userIsReady: true });
                })
                .catch((err) => {
                    console.error("Blueberry.getClaimDetailsFromUserToEdit [error]", err);
                })
        }
    }

    setUser = () => {
        const { user } = this.props.store;

        if (user && user.id && user.profile) {
            const newFormControls = { ...this.state.formControls };
            newFormControls.firstName = {
                ...newFormControls.firstName,
                value: user.profile.firstName,
                valid: true,
            };
            newFormControls.lastName = {
                ...newFormControls.lastName,
                value: user.profile.lastName,
                valid: true,
            };

            if (user.profile.companyName) {
                newFormControls.company = {
                    ...newFormControls.companyName,
                    value: user.profile.companyName,
                    valid: true,
                    disabled: true,
                };
            }

            newFormControls.contactPhoneNumber = {
                ...newFormControls.contactPhoneNumber,
                value: user.profile.contactPhoneNumber,
                valid: true,
            };
            newFormControls.email = {
                ...newFormControls.email,
                value:
                    user && user.emails && user.emails.length > 0
                        ? user.emails[0].address
                        : "",
                valid: true,
            };
            this.setState({ formControls: newFormControls });
        }
    };

    setContact = (contact) => {
        const newFormControls = { ...this.state.formControls };

        newFormControls.clientNumber = {
            ...newFormControls.clientNumber,
            value: contact.clientNumber,
            valid: true,
            disabled: true,
        };

        newFormControls.salesrepId = {
            ...newFormControls.salesrepId,
            value: contact.salesrepId,
            valid: true,
            disabled: true,
        };

        this.setState({ formControls: newFormControls });
    };

    validateForm(formElement) {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    }

    setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    setValueGrid = (index, name, value) => {
        const grid = [...this.state.grid];
        const updatedFormElement = {
            ...grid[index],
        };

        updatedFormElement[name] = value;

        grid[index] = updatedFormElement;

        this.setState({
            grid,
        });
    };

    setGrid = (grid) => {
        this.setState({grid});
    }

	handleSubmitForm = (e) => {
		e.preventDefault();
		const { formControls, grid, claimId } = this.state;
		let hasErrors = false;
		let formData = {};
		const updatedFormElement = { ...formControls };

		for (let formElementId in formControls) {
			if (!formControls[formElementId].valid) {
				hasErrors = true;
				updatedFormElement[formElementId].touched = true;
			}
			formData[formElementId] = formControls[formElementId].value;
		}

		formData["issues"] = grid.map(({initialFiles, pending, isReady, ...grid}) => grid);
		formData["orderNumber"] = this.state.order;
		formData["claimStatus"] = "open";

		this.setState({ isUpdating: true });
		const form_key = "ZHjLubeXX6xTBH6zN";
		const self = this;

		Blueberry.claimForm({
			fields: formData,
			form_key,
			brandSlug: this.props.match.params.brandSlug,
			claimId,
		})
			.then((resp) => {
				if (resp.error) throw new Error(resp.message);
				if (resp.isCustomError) {
					self.setState({ isUpdating: false, success: false, failureDetails: resp });
				} else {
					self.setState({ isUpdating: false, success: true, steps: 4 });
					self.setWindowTop();
				}
			})
			.catch((err) => {
				console.error(err);
				self.setState({ isUpdating: false, success: false, failureDetails: err });
			});
	};

    handleAddNewGridRow = (e, rows = []) => {
        e.preventDefault();
        const grid = [...this.state.grid];

        if (rows.length > 0) grid.push(...rows);
        this.setState({ grid });
    };

    handleRemoveGridRow = (index) => {
        const grid = [...this.state.grid];
        grid.splice(index, 1);
        this.setState({ grid });
    }

    handlePasteItems = (items, rowNumber, startCol) => {
        const grid = [...this.state.grid];
        const self = this;
        for (let index = 0; index < items.length; index++) {
            const v = items[index];

            if (v.type === "text/plain") {
                v.getAsString(function (text) {
                    text = text.trim("\r\n");
                    const split = text.split("\r\n");
                    for (let j = 0; j < split.length; j++) {
                        const v2 = split[j];
                        const split2 = v2.split("\t");
                        for (let x = 0; x < split2.length; x++) {
                            var row = rowNumber + j,
                                col = startCol + x;
                            const v3 = split2[x];

                            if (grid[row] == null) {
                                grid[row] = getNewGridRow(grid.length);
                            }
                            grid[row][col === 1 ? "sku" : "quantity"] = v3;
                        }
                    }

                    self.setState({ grid });
                });
            }
        }
    };

    // specify upload params and url for your files
    getUploadParams = async ({ file, meta: { name, type } }) => {
        const { url, fileUrl } = await Blueberry.signedUrlUpload({
            name,
            type,
        });

        return {
            body: file,
            meta: { fileUrl: fileUrl },
            url: url,
            fields: { acl: "public-read" },
            method: "put",
        };
    };

    // called every time a file's `status` changes
    handleChangeStatus = (rowNumber, { meta, file }, status) => {
        if (status === "done") {
            this.setState((prevState) => {
                const grid = [...prevState.grid];

                if (grid[rowNumber].images == null) {
                    grid[rowNumber].images = [];
                }

                grid[rowNumber].images.push(meta.fileUrl);

                return { grid };
            });
        }
    };

    handleNextStep = () => {
        this.setState((prev) => {
            this.setWindowTop();
            return { steps: prev.steps + 1 };
        });
    };

    handleSubmitFirstStep = () => {
        const { formControls } = this.state;
        let hasErrors = false;
        let formData = {};
        const updatedFormElement = { ...formControls };

        for (let formElementId in formControls) {
            if (!formControls[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControls[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (hasErrors) {
            this.setState({ formControls: updatedFormElement });
            this.setWindowTop();
            return;
        }
        this.setWindowTop();
        this.setState({ steps: 3 });
    };

    clearInitialImagesDropzone = (index, name) => {
        const grid_to_clear = [...this.state.grid];
        if (grid_to_clear[index].initialFiles) {
            delete grid_to_clear[index].initialFiles[name];
        }
        this.setState({ grid: grid_to_clear });
    }

    renderBody() {
        const {
            grid,
            isUpdating,
            salesreps,
            success,
            isModalVisible,
            steps,
            formControls,
            urlParams,
			failureDetails,
        } = this.state;
        const { brandSlug } = this.props.match.params;

        const { user } = this.props.store;
        let data = {
            content: this._t("claimFormDes"),
            title: this._t("claimForm"),
            seo: {
                title: this._t("claimForm") + " - Noritex",
                page: this._t("claimFormDes"),
            },
        };

        return (
            <>
                <Helmet>
                    <title>{data.title}</title>
                    <meta name="description" content={data.description} />
                </Helmet>
                <Listener user={user} action={this.setUser} />
                <NewClaimForm
                    handleNextStep={this.handleNextStep}
                    handleSubmitFirstStep={this.handleSubmitFirstStep}
                    handleSubmitForm={this.handleSubmitForm}
                    steps={steps}
                    userId={user ? user._id : null}
                    brandSlug={brandSlug}
                    grid={grid}
                    formControls={formControls}
                    setContact={this.setContact}
                    setValue={this.setValue}
                    setValueGrid={this.setValueGrid}
                    setGrid={this.setGrid}
                    salesreps={salesreps}
                    validateForm={this.validateForm}
                    isUpdating={isUpdating}
                    handlePasteItems={this.handlePasteItems}
                    handleChangeStatus={this.handleChangeStatus}
                    getUploadParams={this.getUploadParams}
                    handleAddNewGridRow={this.handleAddNewGridRow}
                    handleRemoveGridRow={this.handleRemoveGridRow}
                    addToUrlParams={urlParams}
                    clearInitialImagesDropzone={this.clearInitialImagesDropzone}
					failureDetails={failureDetails}
                />
            </>
        );
    }
}

export default withTracker()(ClaimForm);

// export default withSSR(Page);
