import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { numberWithCommas } from "../utils";


export default function CheckoutItem({imagesUrl, title, title_eng, title_pt, quantity, price}) {
	const img = imagesUrl && imagesUrl.length > 0 ? imagesUrl[0].thumb : "";

	return (
		<tr className="product">
			<td className="product_image">
				<div className="product-thumbnail">
					<div className="product-thumbnail_wrapper">
						<LazyLoadImage src={img} alt={title} className="product-thumbnail_image" />
					</div>
					<span className="product-thumbnail_quantity">{quantity}</span>
				</div>
			</td>
			<th className="product_description">
				<span className="product_name">{title || title_eng || title_pt}</span>
			</th>
			<td className="product_price">
				<span className="order-summary_emphasis">
					${numberWithCommas(quantity * price)}
				</span>
			</td>
		</tr>
	);
}
