import React from "react";
import CheckoutItem from "./CheckoutItem";

export default function ProductTable({ cart, _t, cartsIds }) {
    const [limit, setLimit] = React.useState(50);
    const divRef = React.useRef();
    const elements = [];
    let countCurrent = 0;

    const renderTableBody = (elems) => {
        if (!elements || elems.length === 0) {
            return (
                <tr className="product">
                    <td colSpan={3}>{_t("cartEmpty")}</td>
                </tr>
            );
        }

        return elems;
    };

    const handleOnScroll = () => {
        if (
            divRef.current.offsetHeight + divRef.current.scrollTop >=
            divRef.current.scrollHeight - 1000
        ) {
            setLimit((prev) => prev + 25);
        }
    };

    if (cart == null) return null;
    for (let j = 0; j < cart.length; j++) {
        const group = [];
        const currentCart = cart[j];

        if (cartsIds.indexOf(currentCart._id) > -1) {
            for (
                let index = 0;
                index <
                Math.min(
                    limit - countCurrent,
                    currentCart ? currentCart.items.length : 0
                );
                index++
            ) {
                countCurrent++;
                const item = currentCart.items[index];
                elements.push(
                    <CheckoutItem
                        imagesUrl={item.imagesUrl}
                        title={item.title}
                        title_eng={item.title_eng}
                        title_pt={item.title_pt}
                        quantity={item.quantity}
                        price={item.price}
                        key={item.productId}
                    />
                );
            }
        }
    }

    return (
        <div
            className="product-table-wrapper"
            ref={divRef}
            onScroll={handleOnScroll}
        >
            <table className="product-table">
                <tbody>{renderTableBody(elements)}</tbody>
            </table>
        </div>
    );
}

ProductTable.defaultProps = {
    items: [],
    _t: {},
};
