import React from "react";
import { Container, Section, Title, Heading } from "../../../sdk/Layout";
import { Button } from "../../../sdk/Button";
import { Content, ButtonsExtended, Steps, FieldExtended } from "../style";
import { Field, FieldBody, Control, SelectBox } from "../../../sdk/Form";
import { useTranslation } from "../../../../lang";
import Icons from "./Icons";

function UserInformation({
    handleNextStep,
    setValue,
    lastName,
    company,
    salesrepId,
    clientNumber,
    orderNumber,
    email,
    position,
    comments,
    contactPhoneNumber,
    firstName,
    salesreps,
    validateForm,
    langJson,
    _t,
}) {
    return (
        <Content>
            <Steps>
                <div className="is-active">1</div>
                <span></span>
                <div>2</div>
                <span></span>
                <div>
                    <Icons icon="check"></Icons>
                </div>
            </Steps>
            <Title as="h2" size="5" primary>
                {langJson["firstStepTitle"]}
            </Title>
            <Title as="h4" size="6" marginTop="10" marginBottom="30" color="gray">
                {langJson["firstStepSubTitle"]}
            </Title>
            <Field isHorizontal>
                <FieldBody>
                    <Field>
                        <Control isExpanded>
                            <label>{_t("firstName")}</label>
                            <input
                                type="text"
                                className={`${validateForm(firstName)}`}
                                name="firstName"
                                value={firstName.value}
                                onChange={setValue}
                                autoComplete="off"
                            />
                        </Control>
                    </Field>
                    <Field>
                        <Control isExpanded>
                            <label>{_t("lastName")}</label>
                            <input
                                type="text"
                                className={`${validateForm(lastName)}`}
                                name="lastName"
                                value={lastName.value}
                                onChange={setValue}
                                autoComplete="off"
                            />
                        </Control>
                    </Field>
                </FieldBody>
            </Field>
            <Field isHorizontal>
                <FieldBody>
                    <Field>
                        <Control isExpanded>
                            <label>Email</label>
                            <input
                                type="email"
                                className={`${validateForm(email)}`}
                                name="email"
                                value={email.value}
                                onChange={setValue}
                                autoComplete="off"
                            />
                        </Control>
                    </Field>
                    <Field>
                        <Control isExpanded>
                            <label>{_t("contactPhoneNumber")}</label>
                            <input
                                type="text"
                                className={`${validateForm(
                                    contactPhoneNumber
                                )}`}
                                name="contactPhoneNumber"
                                value={contactPhoneNumber.value}
                                onChange={setValue}
                                autoComplete="off"
                            />
                        </Control>
                    </Field>
                </FieldBody>
            </Field>
            <Field>
                <Control isExpanded>
                    <label>{_t("company")}</label>
                    <input
                        type="text"
                        className={`${validateForm(company)}`}
                        name="company"
                        value={company.value}
                        onChange={setValue}
                        autoComplete="off"
                        disabled={!!company.disabled}
                    />
                </Control>
            </Field>
            <Field isHorizontal>
                <FieldBody>
                    <FieldExtended size="150" isNarrow>
                        <Control isExpanded>
                            <label>{_t("clientNumber")}</label>
                            <input
                                type="text"
                                className={`${validateForm(clientNumber)}`}
                                name="clientNumber"
                                value={clientNumber.value}
                                onChange={setValue}
                                autoComplete="off"
                                disabled={!!clientNumber.disabled}
                            />
                        </Control>
                    </FieldExtended>
                    <FieldExtended>
                        <Control isExpanded>
                            <label>{_t("salesman")}</label>
                            <SelectBox isFullWidth>
                                <select
                                    value={salesrepId.value}
                                    onChange={setValue}
                                    name="salesrepId"
                                    disabled={!!salesrepId.disabled}
                                >
                                    <option>Seleccione Su Vendedor</option>
                                    {salesreps.map((index) => (
                                        <option
                                            key={index._id}
                                            value={index._id}
                                        >
                                            {index.name}
                                        </option>
                                    ))}
                                </select>
                            </SelectBox>
                        </Control>
                    </FieldExtended>
                </FieldBody>
            </Field>
            <ButtonsExtended isRight>
                <Button primary onClick={handleNextStep}>
                    {_t("next")}
                </Button>
            </ButtonsExtended>
        </Content>
    );
}

export default UserInformation;
