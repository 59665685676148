import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import withTracker from "../../blueberry/connect";

import MainTemplate from "../templates/MainTemplate";

import {
    Content,
    Columns,
    Column,
    Section,
    Container,
    Box,
    Title,
    Subtitle,
} from "../sdk/Layout";
import Perfil from "./components/Perfil";
import Tienda from "./components/Tienda";
import Seguridad from "./components/Seguridad";
import Reportes from "./components/Reportes";
import Menu from "./components/Menu";
import { config } from "../../config";

// import Section from "./components/Section";

const Wrap = styled(Container)`
    max-width: 1200px;
`;

const SectionExtended = styled(Section)`
    background-color: ${(props) => props.theme.grayLight};

    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        & ${Box} {
            padding: 15px;
        }
        h3 {
            font-size: 14px;
            color: ${(props) => props.theme.gray};
            font-weight: normal;
            margin-bottom: 10px;
        }
        & ${Subtitle} {
            margin-bottom: 10px;
            font-size: 16px;
            svg {
                display: none;
            }
        }
    }
`;

const BoxHeader = styled(Title)`
    @media (max-width: 980px) ${(props) =>
            !props.theme.isStandalone ? "and (display-mode: standalone)" : ""} {
        margin-bottom: 10px;
        font-size: 1.2rem;
        line-height: 1.125;
        text-transform: uppercase;
    }
`;

class MyAccount extends MainTemplate {
    renderBody() {
        const { user } = this.props.store;

        return (
            <>
                <SectionExtended gray>
                    <Wrap>
                        <Columns>
                            <Column isNarrow className="hide-on-standalone">
                                <Menu _t={this._t} user={user} />
                            </Column>
                            <Column>
                                <BoxHeader size="5">Mi cuenta</BoxHeader>
                                <Box shadowless>
                                    <Content>
                                        <Switch>
                                            <Route path="/:lang/:brandSlug?/account/profile">
                                                <Perfil
                                                    user={user}
                                                    _t={this._t}
                                                />
                                            </Route>
                                            <Route path="/:lang/:brandSlug?/account/store">
                                                <Tienda
                                                    user={user}
                                                    _t={this._t}
                                                />
                                            </Route>
                                            <Route path="/:lang/:brandSlug?/account/security">
                                                <Seguridad _t={this._t} />
                                            </Route>
                                            <Route path="/:lang/:brandSlug?/account/reports">
                                                <Reportes
                                                    _t={this._t}
                                                    user={user}
                                                />
                                            </Route>
                                        </Switch>
                                    </Content>
                                </Box>
                            </Column>
                        </Columns>
                    </Wrap>
                </SectionExtended>
            </>
        );
    }
}

// export default MyAccount;
export default withTracker()(MyAccount);
