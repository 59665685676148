import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, Title, Subtitle } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Control, Field, FieldBody, SelectBox } from "../../sdk/Form";
import Icon from "../../components/Icons2";
import Blueberry from "../../../blueberry";
import ErrorMessage from "../../components/ErrorMessage";
import { isEmailValid } from "../../utils";
import { Preloader } from "../../sdk/Elements";

function Reportes({ user, _t }) {
    const [email, setEmail] = React.useState(
        user && user.emails ? user.emails[0].address : ""
    );
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingReports, setIsLoadingReports] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [sucess, setSucess] = React.useState(false);
    const [reports, setReports] = React.useState(null);

    const onChangeEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };

    const onRequest = (companyId) => {
        if (email === "" || !isEmailValid(email)) return null;
        setIsLoading(true);
        setMessage(null);

        if (typeof window !== "undefined" && analytics)
            analytics.track("Statement Requested");

        Blueberry.requestStatement({
            email,
            companyId,
        })
            .then(() => {
                setIsLoading(false);
                setMessage(
                    `Estado de cuenta solicitado, se le enviara a su correo electronico: ${email}`
                );
                setSucess(true);
            })
            .catch((err) => {
                console.log(err);
                setMessage(err.reason);
                setIsLoading(false);
                setSucess(false);
            });
    };

    React.useEffect(() => {
        setIsLoadingReports(true);
        Blueberry.validateReports({})
            .then((resp) => {
                setReports(resp);
                setIsLoadingReports(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    if (user.roles.findIndex(rol => rol === "read-accounts") < 0) return null;

    return (
        <>
            <Helmet>
                <title>{_t("reports")} - Noritex</title>
            </Helmet>
            <Subtitle as="h2" size="5">
                <Icon icon="file" />
                {_t("reports")}
            </Subtitle>

            {message ? (
                <ErrorMessage errorMessage={message} sucess={sucess} />
            ) : null}

            {isLoadingReports ? (
                <>
                    <Field>
                        <Preloader />
                    </Field>

                    <Field isNarrow>
                        <Preloader half />
                    </Field>
                </>
            ) : reports && reports.integrations ? (
                reports.integrations.map((index) => (
                    <Row>
                        <Title as="h3" size="6">
                            {_t(index.reportName)} -{" "}
                            {index.store
                                ? index.store.storeName
                                : "No Store Name"}
                        </Title>

                        <Field isHorizontal>
                            <FieldBody isFlexEnd>
                                {/* <Field isNarrow className="is-narrow">
                            <label>Mes</label>
                            <Control isExpanded>
                                <SelectBox className="is-fullwidth-mobile">
                                    <select>
                                        <option value="">Enero</option>
                                        <option value="">Febrero</option>
                                        <option value="">Marzo</option>
                                    </select>
                                </SelectBox>
                            </Control>
                        </Field> */}
                                <>
                                    <Field>
                                        <label htmlFor="email">
                                            {_t("email")}
                                        </label>
                                        <Control isExpanded>
                                            <input
                                                type="email"
                                                className="input"
                                                placeholder="mail@example.com"
                                                name="email"
                                                id="email"
                                                value={email}
                                                onChange={onChangeEmail}
                                            />
                                        </Control>
                                    </Field>

                                    <Field isNarrow>
                                        <Button
                                            onClick={() =>
                                                onRequest(index.companyId)
                                            }
                                            primary
                                            static={!isEmailValid(email)}
                                            loading={isLoading}
                                        >
                                            <Icon icon="send" />
                                            <span>{_t("send")}</span>
                                        </Button>
                                    </Field>
                                </>
                            </FieldBody>
                        </Field>
                    </Row>
                ))
            ) : null}
        </>
    );
}

export default Reportes;
