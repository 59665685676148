import styled from "styled-components";

export const MenuList = styled.ul`
    line-height: 1.25;
    list-style: none;
    margin: 0;
    padding: 0;
    a {
        border-radius: 2px;
        color: ${props => props.theme.gray};
        display: block;
        padding: 0.5em 0.75em;
        :hover {
            background-color: ${props => props.theme.white};
            color: ${props => props.theme.primaryDarkMode};
        }
        &.active {
            color: ${props => props.theme.primaryDarkMode};
        }
        svg {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 5px;
        }
    }
    li ul {
        border-left: 1px solid ${props => props.theme.borderColor};
        margin: 0.75em;
        padding-left: 0.75em;
    }
`;

export const MenuLabel = styled.p`
    color: ${props => props.theme.gray};
    font-size: 0.75em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    svg {
        display: none;
    }
`;

export const AsideMenu = styled.div`
    font-size: 1.6rem;

    ${MenuLabel}:not(:first-child) {
        margin-top: 1em;
    }

    ${MenuLabel}:not(:last-child) {
        margin-bottom: 1em;
    }

    @media (max-width: 768px) {
        background-color: ${props => props.theme.white};
        padding-top: 10px;
        padding-right: 0;
        border: solid 1px ${props => props.theme.borderColor};
        border-bottom: none;
        ${MenuLabel} {
            border-bottom: solid 1px ${props => props.theme.borderColor};
            cursor: pointer;
            padding-left: 10px;
            min-height: 24px;
            margin-bottom: 0 !important;
            svg {
                display: inline-block;
                vertical-align: middle;
                float: right;
                margin-right: 10px;
            }
        }
        ${MenuList} {
            border-bottom: solid 1px ${props => props.theme.borderColor};
        }
        ${MenuList}:not(.is-open) {
            display: none;
        }
    }
`;
