import React from "react";
import styled from "styled-components";
import { Section, Container, Heading } from "../../sdk/Layout";
import { Preloader } from "../../sdk/Elements";
import Blueberry from "../../../blueberry";
import InternalLink from "../InternalLink";
import { useTranslation } from "../../../lang";
import useUser from "../../../blueberry/hooks/useUser";
const Brand = styled(InternalLink)`
    display: flex;
    align-items: center;
    margin: 1%;
    figure {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 100%;
        background-color: ${(props) => props.theme.white};
        padding: 10px;

        border: solid 1px ${(props) => props.theme.borderColor};
        margin-right: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${(props) => props.theme.primaryDark};
        margin: 0;
        padding: 0;
        text-align: center;
    }
`;

const BrandList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

function YourBrands({ hideTitle, addToLink }) {
    const user = useUser();
    const [brands, setBrands] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [latestBrands, setLatestBrands] = React.useState(false);
    const [_t] = useTranslation();

    React.useEffect(() => {
        setIsLoading(true);

        Blueberry.myBrands({ limit: 100 })
            .then((resp) => {
                if (resp == null || resp.length === 0) {
                    Blueberry.brandCollections({ limit: 6, page: 1 })
                        .then((resp) => {
                            setBrands(resp && resp.brands ? resp.brands : []);
                            setIsLoading(false);
                            setLatestBrands(true);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            console.log(err);
                        });
                } else {
                    setLatestBrands(false);
                    setIsLoading(false);
                    setBrands(resp ? resp : []);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setLatestBrands(false);
                console.log(err);
            });
    }, [user]);

    if (isLoading) {
        return (
            <Section>
                <Container>
                    <Heading isCentered>
                        <h3>
                            <Preloader />
                        </h3>
                    </Heading>

                    <BrandList>
                        {[1, 2, 3, 4, 5].map((index) => (
                            <Brand key={`brand-preloader-${index}`} to="#">
                                <figure>
                                    <Preloader square />
                                </figure>
                            </Brand>
                        ))}
                    </BrandList>
                </Container>
            </Section>
        );
    }
    return (
        <>
            <Section>
                <Container>
                    {!hideTitle ? (
                        <Heading isCentered>
                            <h3>
                                {latestBrands
                                    ? _t("latestBrands")
                                    : _t("yourBrands")}
                            </h3>
                        </Heading>
                    ) : null}

                    <BrandList>
                        {brands.map((index) => (
                            <Brand
                                to={`/${index.slug}/${
                                    addToLink ? addToLink : "brand/"
                                }`}
                                key={`brand-your-brands-${index._id}`}
                            >
                                <figure>
                                    <img src={index.imageLogo} />
                                </figure>
                            </Brand>
                        ))}
                    </BrandList>
                </Container>
            </Section>
        </>
    );
}

export default YourBrands;
