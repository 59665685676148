/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from "react";
import Dropzone from "react-dropzone-uploader";
import styled from "styled-components";
import {
	Title, Columns, Column, Spacer,
} from "../../../sdk/Layout";
import {
	DropzoneExtended,
	Item,
	ItemDetail,
	ColumnExtended,
	MoreInfo,
} from "../style";
import { Field, Control, Checkbox } from "../../../sdk/Form";
import NoImage from "../../../components/NoImage";
import Icons from "./Icons";
import { Message } from "../../../sdk/Elements";
import Modal from "../../../components/Modal";

const ItemExtended = styled(Item)`
    background-color: ${(props) => props.color || props.theme.grayLight};
    position: relative;
    padding-top: 35px;

	.fail {
		color: #cc0f35;
	}
`;

const RemoveIcon = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #cc0f35;
    :hover {
        color: orangered;
    }
`;

const CheckBoxExtended = styled(Checkbox)``;

const FieldExtended = styled(Field)`
    input {
        width: 100px;
        height: 28px;
        font-size: 12px;
    }
`;

const FieldTextarea = styled(Field)`
    label {
        font-size: 14px;
    }
    textarea {
        font-size: 12px;
    }
    padding-bottom: 20px;
`;

const MessageExtend = styled(Message)`
    margin: 0;
    padding: 5px 10px;
`;

const MessageQuantity = styled(Message)`
    margin: 0;
    padding: 5px 10px;
    display: inline-block;
`;

const HelperText = styled.div`
    &&{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 28px;
    }
`;

const MotiveHeader = styled.label`
    &&{
        display: flex;
        justify-content: space-between;
    }
`;

const MotiveField = styled.div`
    &&{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 6px 0;
        label {
            display: flex;
            align-items: center;
            width: 200px;
            input {
                margin-right: 5px;
            }
            p {
                font-size: 12px;
                font-weight: normal;
                margin: 0;
            }
        }
    }
`;

const CounterLabel = styled.div`
    && {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        font-size: 14px;
        margin: 30px 0 6px 0;
        strong {
            font-weight: bold;
        }
        svg {
            margin-bottom: 5px;
            color: #257942;
            width: 14px;
        }
        span {
            margin-bottom: 5px;
            margin-right: 10px;
        }
    }
`;

const InitialFiles = styled.div`
    && {
        display: flex;
        flex-wrap: wrap;
        img {
            margin: 6px;
            border-radius: 4px;
        }
    }
`;

const InitialImage = styled.div`
    && {
        position: relative;
    }
`;

const MAX_IMAGES = 5;

function MotiveRow({
	field, label, handleChangeQuantity, max,
}) {
	const inputRef = React.useRef(null);

	const handleCheckClic = (e) => {
		if (e.target.checked) {
			inputRef.current.removeAttribute("disabled");
			inputRef.current.focus();
			inputRef.current.select();
		}
	};

	const handleMouseWheel = (e) => {
		e.target.blur();
	};

	return (
		<MotiveField>
			<CheckBoxExtended>
				<label onClick={handleCheckClic}>
					<input
						name="checked"
						type="checkbox"
						onChange={handleChangeQuantity}
						checked={field.checked}
					/>
					<span />
					<p>{label}</p>
				</label>
			</CheckBoxExtended>
			<Field>
				<FieldExtended size="150" isNarrow>
					<Control>
						<input
							ref={inputRef}
							name="value"
							type="number"
							onChange={handleChangeQuantity}
							onWheel={handleMouseWheel}
							autoComplete="off"
							value={field.value}
							disabled={!field.checked}
							min={0}
							max={max}
						/>
					</Control>
				</FieldExtended>
			</Field>
		</MotiveField>
	);
}

function MotiveAlert({ field, langJson, pendingImages }) {
	if ((field.images.length >= field.value || field.images.length >= MAX_IMAGES)
        && field.value > 0
	) return <Icons icon="check" />;

	if (field.images.length >= 1
        && field.value > 0
        && (field.name === "missing" || field.name === "surplus" || field.name === "bulk")
	) return <Icons icon="check" />;

	if (!pendingImages) return null;

	if (
		pendingImages.indexOf(field.name) >= 0
	) return <MessageExtend error>{langJson.claimMissingImages}</MessageExtend>;

	return null;
}

function InlineErrors({
	pending, quantities, sku, failureDetails, langJson,
}) {
	const isQuantitiesOK = (type) => {
		if (type === "overflow") return false;

		let numChecks = 0;
		for (let i = 0; i < quantities.length; i++) {
			const motive = quantities[i];
			numChecks += Number(motive.checked);

			if (motive.checked && motive.value <= 0) return false;
		}

		return (numChecks > 0);
	};

	if (["default", "overflow"].includes(pending) && !isQuantitiesOK(pending)) {
		return (
			<MessageQuantity error>
				{pending === "overflow" ? langJson.claimOverflow : langJson.claimMissingValue}
			</MessageQuantity>
		);
	}

	if (failureDetails && (failureDetails.sku === sku)) {
		return (
			<MessageQuantity error>
				{langJson.claimCustomError[failureDetails.customError]}
			</MessageQuantity>
		);
	}

	return <div />;
}

function GridItem({
	id,
	getUploadParams,
	setValueGrid,
	row,
	langJson,
	rowNumber,
	clearInitialImagesDropzone,
	handleRemoveGridRow,
	failureDetails,
}) {
	const [infoModalVisible, setInfoModalVisible] = React.useState(false);
	const [initialQuantity, setInitialQuantity] = React.useState(0);
	const [quantities, setQuantities] = React.useState([
		{
			name: "broken", checked: false, value: 0, images: [], comments: "",
		},
		{
			name: "poor quality", checked: false, value: 0, images: [], comments: "",
		},
		{
			name: "missing", checked: false, value: 0, images: [], comments: "",
		},
		{
			name: "surplus", checked: false, value: 0, images: [], comments: "",
		},
		{
			name: "bulk", checked: false, value: 0, images: [], comments: "",
		},
		{
			name: "wrong", checked: false, value: 0, images: [], comments: "",
		},
	]);

	const getQuantityVal = (e) => {
		if (
			e.target.value.trim() === "" || Number.isNaN(e.target.value) || e.target.name !== "value"
		) return 0;

		if (e.target.valueAsNumber < 0) return 0;

		if (e.target.valueAsNumber > initialQuantity) return parseInt(initialQuantity);

		return parseInt(e.target.valueAsNumber);
	};

	const handleChangeQuantity = (e, index) => {
		const q = [...quantities];
		q[index][e.target.name] = (e.target.name === "checked") ? e.target.checked : getQuantityVal(e);
		if (e.target.name === "checked" && !e.target.checked) {
			q[index].value = 0;
			q[index].images = [];
			clearInitialImagesDropzone(rowNumber, q[index].name);
		}

		setQuantities(q);
		setValueGrid(rowNumber, "description", q);
	};

	const handleUploadImage = (file, status, index) => {
		if (status === "done") {
			const q = [...quantities];
			q[index].images.push(file.meta.fileUrl);
			setQuantities(q);
			setValueGrid(rowNumber, "description", q);
		}
		if (status === "removed") {
			const q = [...quantities];
			q[index].images = q[index].images.filter((value) => value !== file.meta.fileUrl);
			setQuantities(q);
			setValueGrid(rowNumber, "description", q);
		}
	};

	const handleAddComments = (e, index) => {
		const q = [...quantities];
		q[index].comments = e.target.value;
		setQuantities(q);
		setValueGrid(rowNumber, "description", q);
	};

	const getCounterLabel = (field) => {
		const label = langJson.claimOptionsImages[field.name];
		if (
			field.name === "broken" || field.name === "poor quality"
		) return `${field.images.length} ${langJson.of} ${field.value < MAX_IMAGES ? field.value : MAX_IMAGES} ${label}`;

		return label;
	};

	React.useEffect(() => {
		if (row.description) {
			setQuantities(row.description);
		}
	}, [initialQuantity]);

	React.useEffect(() => {
		setInitialQuantity(row.quantity);
	}, []);

	const handleInfoModal = () => {
		setInfoModalVisible((prevState) => !prevState);
	};

	const handleRemoveItem = () => {
		handleRemoveGridRow(rowNumber);
	};

	return (
		<ItemExtended id={id}>
			<RemoveIcon onClick={handleRemoveItem}>
				<Icons icon="close" />
			</RemoveIcon>
			<Field>
				<Columns>
					<Column>
						<ItemDetail>
							<div className="image">
								{ row.thumb ? <img src={row.thumb} alt="" /> : <NoImage width={80} height={80} /> }
							</div>
							<div className="text">
								<h4>{row.title || "---"}</h4>
								<h4>
									Ref:&nbsp;
									{row.sku}
								</h4>
								<div className="attr">
									<div>
										<span className="label">
											{langJson.claimPrice}
											:&nbsp;
										</span>
										$
										{Number.parseFloat(row.price).toFixed(2)}
									</div>
									<div>
										<span className="label">
											{langJson.claimQuantity}
											:&nbsp;
										</span>
										{initialQuantity}
									</div>
									{
										failureDetails && (failureDetails.sku === row.sku) && (
											<div className="fail">
												<span className="label">
													{langJson.claimQuantityPrev}
													:&nbsp;
												</span>
												{failureDetails.alreadyClaimed}
											</div>
										)
									}
								</div>
							</div>
						</ItemDetail>
					</Column>
					<ColumnExtended>
						<Field>
							<MotiveHeader>
								<span>{langJson.claimMotive}</span>
								<span>{langJson.quantityToClaim}</span>
							</MotiveHeader>
							{
								quantities.map((field, index) => (
									<MotiveRow
										key={`motive-claim-${index}`}
										field={field}
										label={langJson.claimOptions[index]}
										handleChangeQuantity={(e) => handleChangeQuantity(e, index)}
										max={initialQuantity}
									/>
								))
							}
						</Field>
						<HelperText>
							<InlineErrors
								pending={row.pending}
								quantities={quantities}
								sku={row.sku}
								failureDetails={failureDetails}
								langJson={langJson}
							/>
							<label>
								<MoreInfo onClick={() => handleInfoModal()}>{langJson.moreInfo}</MoreInfo>
							</label>
						</HelperText>
						<Modal visible={infoModalVisible} close={handleInfoModal}>
							{
								langJson.claimOptions.map((value, index) => (
									<div key={`claim-info-${index}`}>
										<Title as="h4" size="5" marginBottom="10">{value}</Title>
										<p>{langJson.claimOptionsDetail[index]}</p>
										<Spacer height="20" />
									</div>
								))
							}
						</Modal>
					</ColumnExtended>
				</Columns>
			</Field>
			{
				quantities.map((field, index) => (
					(field.checked) && (
						<div key={`dropzone-${index}`}>
							<CounterLabel>
								<span>
									<strong>{langJson.claimOptions[index]}</strong>
									:&nbsp;
									{getCounterLabel(field, index)}
								</span>
								<MotiveAlert field={field} langJson={langJson} pendingImages={row.pending} />
							</CounterLabel>
							<DropzoneExtended direction="column">
								{
									(row.initialFiles) && (
										<InitialFiles>
											{
												(row.initialFiles[field.name])
												&& row.initialFiles[field.name].map((file, iindex) => (
													<InitialImage key={`iimage-${field.name}-${iindex}`}>
														<img src={file} width="auto" height={50} alt="" />
													</InitialImage>
												))
											}
										</InitialFiles>
									)
								}
								<Dropzone
									getUploadParams={getUploadParams}
									onChangeStatus={(file, status) => handleUploadImage(file, status, index)}
									accept="image/*,video/*"
									maxSizeBytes={1024*1024*50}
									inputContent={langJson.dragAndDrop}
									SubmitButtonComponent={null}
									styles={{
										preview: { padding: 10 },
										inputLabel: {
											textAlign: "center",
											maxWidth: "400px",
											margin: "auto",
										},
									}}
									inputWithFilesContent={langJson.addFiles}
								/>
							</DropzoneExtended>
							<FieldTextarea>
								<label>{langJson.comments}</label>
								<Control isExpanded>
									<textarea
										rows="3"
										placeholder={langJson.commentsPlaceholder}
										onChange={(e) => handleAddComments(e, index)}
										value={field.comments || ""}
									/>
								</Control>
							</FieldTextarea>
						</div>
					)
				))
			}
		</ItemExtended>
	);
}

export default GridItem;
