import React from "react";
import styled from "styled-components";

import { Columns, Column } from "../sdk/Layout";
import { Radio } from "../sdk/Form";
import { Preloader } from "../sdk/Elements";
import Breadcrums from "../components/Breadcrums2";
import InternalLink from "../components/InternalLink";

export const Col = styled(Column)`
    padding: 10px;
`;

export const Block = styled.div`
    & + & {
        margin-top: 40px;
    }
    @media (max-width: 480px) {
        & + & {
            margin-top: 30px;
        }
    }
`;

export const Header = styled.div`
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: 480px) {
        margin-bottom: 20px;
    }
`;

export const Cols = styled(Columns)`
    margin: 0 !important;
`;

export const Left = styled(Column)`
    padding-right: 50px;
    padding-left: 20px;
    min-height: 100vh;

    @media (max-width: 980px) {
        padding-right: 20px;
    }

    @media (max-width: 768px) {
        background-color: ${(props) => props.theme.grayLight};
        border-bottom: solid 1px ${(props) => props.theme.borderColor};
    }

    @media (max-width: 480px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;
export const Right = styled(Column)`
    padding-left: 50px;
    padding-right: 20px;
    @media (max-width: 980px) {
        padding-left: 20px;
    }
    @media (max-width: 480px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;
export const RadioExtended = styled(Radio)`
    width: 100%;
    /* margin-top: 10px; */
    label {
        background-color: ${(props) => props.theme.white};
        height: 65px;
        justify-content: start;
    }
    p {
        padding-left: 15px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    border: 0;
    /* margin-bottom: 10px; */
    /* margin-right: 10px; */
    min-width: 100px;
    select {
        z-index: 2;
        padding: 1.96rem 0.875rem 0.825rem;
        border: 1px solid
            ${(props) =>
                !props.valid ? props.theme.red : "hsla(0,0%,49.8%,.3)"};
        border-radius: 3px;
        width: 100%;
        height: 100%;
        /* padding-right: 2.8rem; */
        cursor: pointer;
        background-color: transparent;
        /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: ${(props) => props.theme.white};
        font-size: 15px;
        color: ${(props) => props.theme.primaryDark};
    }
    label {
        position: absolute;
        top: 0.56rem;
        font-size: 1.12rem;
        left: 1.155rem;
        z-index: 2;
        pointer-events: none;
        opacity: 0.6;
        transition: 0.12s;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        color: 1px solid ${(props) => (!props.valid ? props.theme.red : "")};
    }
    span {
        color: ${(props) => props.theme.primaryDark};
        text-decoration: none;
        transition: color 0.1s ease;
        svg {
            position: absolute;
            top: 50%;
            right: 0.875rem;
            margin-top: -5px;
            pointer-events: none;
        }
    }
`;

export const Wrapper = styled.div`
    padding: 0;
    position: relative;
    height: 100%;
    @media (min-width: 769px) {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background-color: ${(props) => props.theme.grayLight};
            border-right: solid 1px ${(props) => props.theme.borderColor};
            z-index: 0;
        }
    }
    .overflow-hidden {
        overflow-y: auto;
        /* height: 100vh; */
    }
`;

export const LogoWrapper = styled.div`
    white-space: nowrap;
    width: auto;
    svg {
        fill: ${(props) => props.theme.primaryDarkMode};
    }
    img {
        display: block;
        margin: auto;
    }
    @media (max-width: 720px) {
        align-self: left;
        margin: 0;
        padding-left: 0;
    }
`;

export const CartWrapper = styled.div`
    // background-color: ${(props) => props.theme.grayLight};
    /* height: 100vh; */
    width: 100%;
    &:after {
        left: 0;
        background-position: left top;
        ${
            "" /* -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
	    box-shadow: 1px 0 0 #e1e1e1 inset; */
        }
        content: "";
        display: block;
        /* width: 300%; */
        position: absolute;
        top: 0;
        /*left: -100%;*/
        bottom: 0;
        background: #fafafa;
        z-index: -1;
    }

    @media (max-width: 767px) {
        //	height: ${(props) => (props.visible ? "auto" : "0")};
        //	visibility: ${(props) => (props.visible ? "visible" : "hidden")};
        &:after {
            background: none;
        }
        height: auto;
        margin-bottom: 1.2em;
    }
`;

export const InformationWrapper = styled.div`
    /* background-color: ${(props) => props.theme.white}; */
    padding-top: 30px;

    @media (max-width: 768px) {
        margin-top: 0;
        padding-top: 10px;
    }
`;

export const CartInfoWrapper = styled(InformationWrapper)`
    /* background-color: #fafafa;
    padding-left: 3%;
    padding-bottom: 6%;

    @media (max-width: 767px) {
        padding-left: 10px;
    } */
`;

export const BreadcrumsExtended = styled(Breadcrums)`
    display: inline-block;
    margin: 0px auto 10px;
    padding-left: 0 !important;
    /* ul {
        display: block;
        text-align: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 20px auto;
        padding-left: 0;
    } */
    ul {
        li {
            a {
                color: ${(props) => props.theme.gray};
            }
            &:last-child {
                a {
                    padding-right: 0;
                    &.active {
                        color: ${(props) => props.theme.primaryDark};
                        font-weight: bold;
                    }
                }
            }
        }
    }
`;

export const HeaderWrapper = styled.header`
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    h2 {
        font-size: 1.96rem;
        font-weight: 400;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.primaryDark};
    }
    button {
        margin: 20px 0 !important;
    }

    .down-arrow {
        width: 8px;
        height: 8px;
        border-right: solid 2px ${(props) => props.theme.primaryDark};
        border-bottom: solid 2px ${(props) => props.theme.primaryDark};
        transform: rotate(45deg);
        margin-left: 10px;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 16px;
        }
    }
`;

export const HeaderColumnWrapper = styled.header`
	line-height: 1.4rem;
	margin: ${(props) => props.margin || "2.5em auto 1em auto"};
	p {
		font-size: 1.4rem;
	}
}
`;

export const SectionWrapper = styled.section`
    border: 1px solid ${(props) => props.theme.borderColor};
    border-radius: 10px;
`;

export const SectionFill = styled.div`
    background-color: ${(props) => props.theme.grayLight};
    /* padding: 1.4rem; */

    & > .rccs {
        padding-top: 0.5em;
    }

    & > p {
        text-align: center;
        font-size: 0.8571428571em;
        width: 60%;
        margin: 0 auto;
        line-height: 2.52rem;
        padding: 2em 0;
    }
    .row {
        &:after {
            content: "";
            display: block;
            clear: both;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        .is-half {
            @media (min-width: 769px) {
                width: 49%;
                float: left;
                &:first-child {
                    margin-right: 1%;
                }
                &:last-child {
                    margin-left: 1%;
                }
            }
            @media (max-width: 768px) {
                & + .is-half {
                    margin-top: 15px;
                }
            }
        }
    }
`;

export const ActionBack = styled(InternalLink)`
    color: ${(props) => props.theme.gray};
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    svg {
        fill: ${(props) => props.theme.gray};
        margin-right: 2px;
        width: 15px;
        height: 15px;
    }
    &:hover {
        color: ${(props) => props.theme.primaryDark};
        svg {
            fill: ${(props) => props.theme.primaryDark};
        }
    }
`;

export const ReviewBox = styled.section`
    padding-top: 0;
    & + & {
        margin-top: 1em;
    }
`;

export const ContentBox = styled(Block)`
    /* border: 1px solid ${(props) => props.theme.borderColor}; */
    /* background: ${(props) => props.theme.white}; */
    /* border-radius: 5px; */
    /* padding: 10px; */
    h5,
    p {
        margin-bottom: 10px;
    }
`;

export const ContentTable = styled.div`
    //padding: 1rem;
    /*display: table;*/
    /*-webkit-box-sizing: border-box;*/
    /*box-sizing: border-box;*/
    /*width: 100%;*/
`;

export const ContentRow = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    & + & {
        border-top: ${(props) =>
            props.noBorder ? "none" : `1px solid ${props.theme.borderColor}`};
    }
    & > a {
        font-size: 0.8571428571em;
        color: ${(props) => props.theme.textDefaultGrayed};
        padding: 1rem;
    }
    & > span {
        color: ${(props) => props.theme.primary};
        font-weight: 500;
        padding: 1rem;
    }
`;

export const ContentRowContent = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 1rem;
`;

export const ContentRowLabel = styled.div`
    padding-right: 0.8m;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 5em;
    -ms-flex: 0 1 5em;
    flex: 0 1 5em;
    font-size: 0.8571428571em;
    color: ${(props) => props.theme.textDefaultGrayed};
`;

export const ContentRowLabelContent = styled.div`
    -webkit-box-flex: 5;
    -webkit-flex: 5;
    -ms-flex: 5;
    flex: 5;
    color: ${(props) => props.theme.primaryDark};
    padding-right: 1.1428571429em;
`;

export const OrderSummarySections = styled.div`
    @media (min-width: 768) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        height: calc(100vh - 8em);
        width: 100%;
    }
    min-width: 300px;
`;

export const OrderSummarySection = styled.div`
    @media (min-width: 768px) {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        min-width: 300px;

        &.product-list {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 0;
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            min-height: 300px;
            overflow: hidden;
            padding: 0;
            padding-top: 0px;
            position: relative;
            &:before {
                background: transparent;
                top: 0;
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                -webkit-transition: background-color 0.3s
                    cubic-bezier(0.3, 0, 0, 1);
                transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
                width: 100%;
                z-index: 4;
            }
            &:after {
                background-color: ${(props) => props.theme.borderColor};
                bottom: 0;
                content: "";
                display: block;
                height: 1px;
                position: absolute;
                -webkit-transition: background-color 0.3s
                    cubic-bezier(0.3, 0, 0, 1);
                transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
                width: 100%;
                z-index: 4;
            }
        }

        &.product-list + & {
            border: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    padding-top: 1.2em;
    padding-bottom: 1.2em;

    .product {
        &:first-child td {
            padding-top: 0;
        }
    }

    .product-table {
        margin-top: 0.75em;
        margin-bottom: 1.5em;
        position: relative;
        z-index: 1;
        font-size: 0.8571428571em;
        &-wrapper {
            @media (min-width: 769px) {
                max-height: calc(100vh - 280px);
            }
            min-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        td,
        th {
            vertical-align: middle;
            padding: 5px 0;
        }
    }

    .product-thumbnail {
        width: 4.6em;
        height: 4.6em;
        border-radius: 8px;
        background: ${(props) => props.theme.white};
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 8px;
            border: 1px rgba(0, 0, 0, 0.1) solid;
            z-index: 2;
        }
    }

    .product-thumbnail_wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        z-index: 1;
    }

    .product-thumbnail_image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    .product-thumbnail_quantity {
        font-size: 0.8571428571em;
        font-weight: 500;
        line-height: 1.75em;
        white-space: nowrap;
        text-align: center;
        border-radius: 1.75em;
        background-color: ${(props) => props.theme.gray};
        color: ${(props) => props.theme.white};
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        min-width: 1.75em;
        height: 1.75em;
        padding: 0 0.5833333333em;
        position: absolute;
        right: -0.75em;
        top: -0.75em;
        z-index: 3;
    }

    .product_description {
        width: 100%;
    }

    .order-summary_emphasis {
        color: ${(props) => props.theme.primaryDarkMode};
        font-weight: 500;
    }

    .product_name {
        width: 1em;
        min-width: 100%;
        display: block;
        padding: 0px 10px;
        color: ${(props) => props.theme.primaryDark};
    }

    .product_price {
        white-space: nowrap;
    }

    .order-summary_small-text {
        font-size: 0.8571428571em;
    }

    & ~ & {
        border-top: 1px solid rgba(175, 175, 175, 0.34);
    }

    .total-line {
        &:first-child td,
        &:first-child th {
            padding-top: 0;
        }
        th,
        td {
            padding-top: 0.75em;
        }
    }

    .total-line_price {
        padding-left: 1.5em;
        text-align: right;
        white-space: nowrap;
    }

    .total-line-table_tbody .tbody-separator,
    .total-line-table_tbody.tbody-separator {
        padding-top: 2.4em !important;
        position: relative;
    }

    .total-line-table_footer .total-line:first-child th,
    .total-line-table_footer .total-line:first-child td {
        padding-top: 3em;
        position: relative;
    }

    .total-line-table_tbody .tbody-separator::before,
    .total-line-table_footer .total-line:first-child th::before,
    .total-line-table_footer .total-line:first-child td::before {
        content: "";
        position: absolute;
        top: 1.2em;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.borderColor};
    }

    .payment-due-label_total {
        font-size: 1.1428571429em;
        color: ${(props) => props.theme.primaryDarkMode};
        text-transform: capitalize;
    }

    .payment-due_currency {
        color: ${(props) => props.theme.gray};
        font-size: 0.8571428571em;
        margin-right: 0.5em;
    }

    .payment-due_price {
        color: ${(props) => props.theme.primaryDarkMode};
        font-size: 1.7142857143em;
        font-weight: 500;
        letter-spacing: -0.04em;
        line-height: 1em;
    }

    table {
        width: 100%;
        font-size: 0.85em;
    }

    td,
    th {
        padding-left: 1em;
        text-align: left;
        font-weight: normal;
    }

    td:first-child,
    th:first-child {
        padding-left: 0;
        text-align: left;
    }

    td:last-child,
    th:last-child {
        text-align: right;
    }

    a svg {
        width: 1em;
        height: 1em;
        color: ${(props) => props.theme.textDefaultGrayed};
        fill: currentColor;
        margin-left: 0.2857142857em;
    }
`;

export const OrderSummarySectionContent = styled.div`
    @media (min-width: 768px) {
        overflow: hidden;
        min-width: 100%;
        max-height: calc(100vh - 280px);
        -webkit-overflow-scrolling: touch;
    }
`;

export const LoadingWrapper = styled.div`
    height: calc(100vh - 172px);
    :only-child {
        padding-top: 30px;
    }
`;
