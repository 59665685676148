import { doCalculations, redirectToCart } from "./cart";
import {
    doFieldChange,
    getCardIssuer,
    getCountriesIsoCode,
    getCountryIsoCode,
    isFieldValid,
    populateFormFromStorage,
    validateFormFields,
} from "./forms";
import {
    getCheckoutMessage,
    getOrderFromAPI,
    getOrderFromStorage,
    hasAdditionalPayment,
    hasValidPaymentStatus,
} from "./orders";

const getNextStepRoute = (lang, skipPayment, skipShipping, token) => {
    let route = `/${lang}`;

    if (!skipPayment && skipShipping) {
        // Skip shipping but not payment
        route = `${route}/checkout/payment/`;
    } else if (skipPayment && !skipShipping) {
        // Skip payment but not shipping
        route = `${route}/checkout/shipping/`;
    } else if (skipPayment && skipShipping) {
        // Skip both checkout steps, means is a quote
        route = `${route}/order-confirmed/${token}`;
    } else {
        route = `${route}/checkout/shipping/`; // Follow the regular checkout process
    }

    return route;
};

function isAnOrdersRoute(path) {
    return path.includes("orders");
}

const buildCheckoutNavigationRoute = (lang, url, page, token) => {
    if (isAnOrdersRoute(url)) return `${lang}/orders/${token || ""}${page}`;

    return `${lang}/checkout${page}`;
};

export default {
    buildCheckoutNavigationRoute,
    doCalculations,
    doFieldChange,
    getCardIssuer,
    getCheckoutMessage,
    getCountriesIsoCode,
    getCountryIsoCode,
    getNextStepRoute,
    getOrderFromAPI,
    getOrderFromStorage,
    hasAdditionalPayment,
    hasValidPaymentStatus,
    isAnOrdersRoute,
    isFieldValid,
    populateFormFromStorage,
    redirectToCart,
    validateFormFields,
};
