import React from "react";
import { Helmet } from "react-helmet";
import Blueberry from "../../blueberry";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connectPromise";
import { Button } from "../sdk/Button";
import Icon from "../components/Icon";
import { Container, Columns, Column, Title } from "../sdk/Layout";
import { Field, FieldBody, Control } from "../sdk/Form";
import Logo from "../components/Logo";
import ProductTable from "./ProductTable";
import TotalsTable from "./TotalsTable";
import { numberWithCommas } from "../utils";
import helpers from "./helpers/index";

import {
    ActionBack,
    Wrapper,
    LogoWrapper,
    InformationWrapper,
    BreadcrumsExtended,
    HeaderWrapper,
    ContentBox,
    ContentTable,
    ContentRowLabel,
    ContentRowContent,
    ContentRowLabelContent,
    ContentRow,
    Left,
    ReviewBox,
    Cols,
    RadioExtended,
    Block,
} from "./CheckoutStyling";
import CartTable from "./components/CartTable";
import Listener from "../components/Listener";

const {
    buildCheckoutNavigationRoute,
    getNextStepRoute,
    getOrderFromStorage,
    isAnOrdersRoute,
    redirectToCart,
} = helpers;

class ShippingOptions extends MainTemplate {
    constructor(props) {
        super(props);

        const config = getOrderFromStorage();

        this.state = {
            formControls: {
                shippingRate: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                    },
                },
            },
            ...config,
            shippingMethod: {},
            nextButtonText: this._t("continueWithPayments"),
        };
        this._redirectToCart = redirectToCart.bind(this);
    }

    _getShippingConfig = () => {
        const { skipPayment: skip } = this.state;

        if (
            !isAnOrdersRoute(this.props.match.url) &&
            typeof skip === "undefined"
        ) {
            this.setState({
                isRefreshing: true,
            });

            Blueberry.shippingConfiguration()
                .then(({ skipPayment }) => {
                    this.setState({
                        nextButtonText: this._renderNextButtonText(skipPayment),
                        skipPayment,
                        isRefreshing: false,
                    });
                })
                .catch((err) => {
                    console.log("Error getting available shipping config", err);
                    this.setState({ isRefreshing: false });
                });
        }
    };

    _getShippingMethods() {
        const { public_token, shippingMethod: methods } = this.state;

        if (!methods || Object.keys(methods).length === 0) {
            this.setState({
                isRefreshing: true,
            });

            Blueberry.shippingMethods({ public_token })
                .then((shippingMethods) => {
                    // console.log("Available shipping methods: ", shippingMethods);
                    const shippingMethod = {
                        shippingId:
                            shippingMethods.length > 0
                                ? shippingMethods[0]._id
                                : null,
                        rates:
                            shippingMethods.length > 0
                                ? shippingMethods[0].rates
                                : null,
                    };

                    this.setState({
                        shippingMethod,
                        shippingRate: shippingMethod.rates[0],
                        isRefreshing: false,
                    });
                })
                .catch(() => {
                    console.log("Error getting shipping methods");
                    this.setState({ isRefreshing: false });
                });
        }
    }

    _renderNextButtonText = (skipPayment) => {
        if (skipPayment) return this._t("continueWithQuote");

        return this._t("continueWithPayments");
    };

    componentDidMount() {
        const { public_token } = this.state;

        if (public_token.length === 0) return this._redirectToCart(this.props);

        if (!isAnOrdersRoute(this.props.match.url)) this._getShippingConfig();

        return this._getShippingMethods();
    }

    renderHeader = () => {
        return null;
    };

    renderFooter = () => {
        return null;
    };

    renderShippingMethod = (shippingRate, rates, calculations) => {
        const { total } = calculations;
        const filteredRates =
            typeof rates !== "undefined" &&
            rates.filter(
                (rate) =>
                    rate.condition.min <= total && rate.condition.max >= total
            );

        if (typeof rates === "undefined" || filteredRates === 0) {
            return (
                <p>
                    {this._t("noShippingAvailable")} <br />
                    {this._t("contactForShippingFulfill")}
                </p>
            );
        }

        const elements = filteredRates.map((rate) => {
            return (
                <RadioExtended key={rate.name}>
                    <label>
                        <input
                            type="radio"
                            name="shippingRate"
                            checked={shippingRate.name === rate.name}
                            aria-checked={shippingRate.name === rate.name}
                            value={rate.rate}
                            onChange={() => this.handleRateSelection(rate)}
                            required
                        />

                        <span></span>
                        <p>
                            {rate.name} - ${numberWithCommas(rate.rate)}
                        </p>
                    </label>
                </RadioExtended>
            );
        });

        return elements;
    };

    handleRateSelection = (rate) => {
        this.setState({
            shippingRate: rate,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { shippingMethod, shippingRate, public_token, skipPayment } =
            this.state;
        const { lang } = this.props.match.params;
        const { url } = this.props.match;

        this.setState({ isUpdating: true });

        const params = {
            shippingId: shippingMethod.shippingId,
            rate: shippingRate,
            public_token,
        };
        const route = isAnOrdersRoute(url)
            ? buildCheckoutNavigationRoute(
                  `/${lang}`,
                  url,
                  "/payment",
                  public_token
              )
            : getNextStepRoute(lang, skipPayment, true, public_token);

        Blueberry.orderShippingRate({ ...params, forseCloseCart: skipPayment })
            .then(() => {
                if (skipPayment) {
                    Blueberry.getCart();
                    localStorage.removeItem("ntx-order-token");
                } else {
                    Blueberry.orderByToken({ public_token }).then((order) => {
                        const copyOrder = { ...order };
                        copyOrder.shippingRate = shippingRate;
                        localStorage.setItem(
                            "ntx-order-token",
                            JSON.stringify(copyOrder)
                        );
                    });
                }
                this.props.history.push(route);
            })
            .catch((err) => {
                console.log("Error setting order shipping rate", err);
                this.setState({ hasError: true });
            })
            .finally(this.setState({ isUpdating: false }));
    };

    renderBody() {
        const { lang } = this.props.match.params;
        const { url } = this.props.match;
        const { stats, user } = this.props.store;
        const { items: cartItems } = this.props.store.cart;
        const {
            firstName,
            lastName,
            email,
            address1,
            address2,
            city,
            region,
            country,
        } = this.state.shippingAddress;
        const {
            calculations,

            shippingMethod,
            shippingRate: rate,
            nextButtonText,
            isRefreshing,
        } = this.state;

        return (
            <Wrapper className="site-page">
                <Helmet htmlAttributes={{ lang }}>
                    <title>Checkout / Envíos</title>
                    <body className="reset-body" />
                </Helmet>

                <Container>
                    <Cols>
                        <Left>
                            <InformationWrapper>
                                <LogoWrapper>
                                    <Logo />
                                </LogoWrapper>
                                {(!isAnOrdersRoute(url) && (
                                    <BreadcrumsExtended
                                        breadcrums={[
                                            {
                                                to: "/cart",
                                                title: this._t("cart"),
                                            },
                                            {
                                                to: "/checkout",
                                                title: this._t("information"),
                                            },
                                            {
                                                to: "/checkout/shipping",
                                                title: this._t("ship"),
                                            },
                                        ]}
                                    />
                                )) || <br />}
                                <ReviewBox>
                                    <ContentBox>
                                        <ContentTable>
                                            <ContentRow>
                                                <ContentRowContent>
                                                    <ContentRowLabel>
                                                        {this._t("contact")}{" "}
                                                    </ContentRowLabel>
                                                    <ContentRowLabelContent>
                                                        {firstName} {lastName}{" "}
                                                        {"<"}
                                                        {email}
                                                        {">"}
                                                    </ContentRowLabelContent>
                                                </ContentRowContent>
                                                {!isAnOrdersRoute(url) && (
                                                    <ActionBack to="/checkout/">
                                                        {this._t("change")}
                                                    </ActionBack>
                                                )}
                                            </ContentRow>
                                            <ContentRow>
                                                <ContentRowContent>
                                                    <ContentRowLabel>
                                                        {this._t("shipTo")}
                                                    </ContentRowLabel>
                                                    <ContentRowLabelContent>
                                                        {address1} {address2},{" "}
                                                        {city}, {region},{" "}
                                                        {country}
                                                    </ContentRowLabelContent>
                                                </ContentRowContent>
                                                {!isAnOrdersRoute(url) && (
                                                    <ActionBack to="/checkout/">
                                                        {this._t("change")}
                                                    </ActionBack>
                                                )}
                                            </ContentRow>
                                        </ContentTable>
                                    </ContentBox>
                                </ReviewBox>

                                <Block>
                                    <HeaderWrapper>
                                        <h2>{this._t("ship")}</h2>
                                    </HeaderWrapper>
                                    <Field>
                                        {shippingMethod && rate && calculations
                                            ? this.renderShippingMethod(
                                                  rate,
                                                  shippingMethod.rates,
                                                  calculations
                                              )
                                            : null}
                                    </Field>
                                </Block>
                                <HeaderWrapper>
                                    {!isAnOrdersRoute(url) && (
                                        <ActionBack to="/checkout/">
                                            <Icon icon="chevron-left" />
                                            {this._t("backToInfo")}
                                        </ActionBack>
                                    )}
                                    <Button onClick={this.handleSubmit}>
                                        {nextButtonText}
                                    </Button>
                                </HeaderWrapper>
                            </InformationWrapper>
                        </Left>
                        <CartTable
                            isRefreshing={isRefreshing}
                            cartItems={cartItems}
                            isServer={this.isServer}
                            stats={stats}
                        />
                    </Cols>
                </Container>

                <Listener user={user} action={this._getShippingConfig} />
            </Wrapper>
        );
    }
}

export default withTracker((props) => {
    // Do all your reactive data access in this method.
    // Note that this subscription will get cleaned up when your component is unmounted

    // console.log("with tracker", props)
    return [
        "menus",
        "user",
        "collections",
        "products",
        "tags",
        "search",
        "cart",
        "token",
        "stats",
        "collectionsList",
    ];
})(ShippingOptions);
