import { useState, useEffect } from "react";
import { BlueberryData } from "../BlueberryData";

function useUser() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (BlueberryData.user && BlueberryData.user.id)
            setUser(BlueberryData.user);
    }, [BlueberryData.user ? BlueberryData.user.id : null]);
    return user;
}

export default useUser;
