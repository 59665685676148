/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";
import {
	Container, Section, Heading, Subtitle,
} from "../../sdk/Layout";
import Explanation from "./compt/Explanation";
import UserInformation from "./compt/UserInformation";
import ProductsForClaim from "./compt/ProductsForClaim";
import Final from "./compt/Final";
import { useTranslation } from "../../../lang";
import langEs from "./lang/lang_es";
import langEn from "./lang/lang_en";
import langPt from "./lang/lang_pt";
import YourBrands from "../../components/YourBrands";
import Blueberry from "../../../blueberry";

function getLang(lang) {
	switch (lang) {
		case "es":
			return langEs;
		case "en":
			return langEn;
		case "pt":
			return langPt;
		default:
			return langEs;
	}
}

function ClaimForm({
	handleNextStep,
	steps,
	formControls,
	setValue,
	salesreps,
	validateForm,
	getUploadParams,
	handleChangeStatus,
	handleAddNewGridRow,
	handleRemoveGridRow,
	handlePasteItems,
	setValueGrid,
	setGrid,
	handleSubmitForm,
	grid,
	handleSubmitFirstStep,
	isUpdating,
	brandSlug,
	userId,
	setContact,
	addToUrlParams,
	clearInitialImagesDropzone,
	failureDetails,
}) {
	const {
		firstName,
		lastName,
		company,
		clientNumber,
		salesrepId,
		orderNumber,
		position,
		contactPhoneNumber,
		email,
		comments,
	} = formControls;

	const [_t, lang] = useTranslation();
	const langJson = getLang(lang);

	React.useEffect(() => {
		if (brandSlug || steps > 0) {
			Blueberry.getUsersInfoForStore({ brandSlug })
				.then((resp) => {
					setContact(resp);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [brandSlug, userId]);

	return (
		<>
			<Helmet>
				<title>{_t("claimForm")}</title>
				<body className="" />
			</Helmet>
			<Container>
				<Section>
					<Heading isCentered>
						<h1>{_t("claimForm")}</h1>
					</Heading>

					{steps === 0 ? (
						<>
							<Subtitle size="6" isCentered>
                                Please select the brand you would like to submit
                                a claim form:
							</Subtitle>
							<YourBrands hideTitle addToLink={addToUrlParams} />
						</>
					) : null}
					{steps === 1 && (
						<Explanation
							handleNextStep={handleNextStep}
							langJson={langJson}
							brandSlug={brandSlug}
						/>
					)}
					{steps === 2 && (
						<UserInformation
							firstName={firstName}
							lastName={lastName}
							company={company}
							langJson={langJson}
							clientNumber={clientNumber}
							salesrepId={salesrepId}
							orderNumber={orderNumber}
							setValue={setValue}
							validateForm={validateForm}
							position={position}
							contactPhoneNumber={contactPhoneNumber}
							email={email}
							comments={comments}
							handleNextStep={handleSubmitFirstStep}
							_t={_t}
							salesreps={salesreps}
						/>
					)}
					{steps === 3 && (
						<ProductsForClaim
							getUploadParams={getUploadParams}
							handleChangeStatus={handleChangeStatus}
							handleSubmitForm={handleSubmitForm}
							handleAddNewGridRow={handleAddNewGridRow}
							handleRemoveGridRow={handleRemoveGridRow}
							handlePasteItems={handlePasteItems}
							setValueGrid={setValueGrid}
							setGrid={setGrid}
							grid={grid}
							langJson={langJson}
							_t={_t}
							isUpdating={isUpdating}
							clearInitialImagesDropzone={clearInitialImagesDropzone}
							failureDetails={failureDetails}
						/>
					)}
					{steps === 4 && (
						<Final
							brandSlug={brandSlug}
							langJson={langJson}
							_t={_t}
						/>
					)}
				</Section>
			</Container>
		</>
	);
}

export default ClaimForm;
