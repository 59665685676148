import styled from "styled-components";
import { Buttons } from "../../sdk/Button";
import { Field, Checkbox } from "../../sdk/Form";
import { Column } from "../../sdk/Layout";
import { Modal } from "../../sdk/Elements";

export const Content = styled.div`
    margin: auto;
    max-width: 700px;
    p {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        color: ${(props) => props.theme.primaryDark};
    }
    ul {
        padding: 0;
        margin: 0;
        padding-left: 15px;
        li {
            list-style: initial;
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 24px;
            color: ${(props) => props.theme.primaryDark};
            li {
                margin-left: 20px;
                list-style: circle;
            }
        }
    }
    h3 {
        margin-bottom: 10px;
        margin-top: 30px;
    }
    @media (max-width: 768px) {
        p,
        ul li {
            font-size: 14px;
            line-height: 20px;
        }
        h3 {
            margin-top: 20px;
        }
    }
`;

export const Steps = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 50px;
    > div {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.blueLight};
        font-size: 16px;
        font-weight: bold;
        color: ${(props) => props.theme.primaryDarkMode};
        border-radius: 100%;
        flex: none;
        margin: 0px 10px;
        svg {
            width: 20px;
            color: ${(props) => props.theme.primaryDarkMode};
        }
        &.is-active,
        &.is-completed {
            background-color: ${(props) => props.theme.primaryDarkMode};
            color: ${(props) => props.theme.white};
            svg {
                color: ${(props) => props.theme.white};
            }
        }
    }
    > span {
        display: block;
        height: 1px;
        background-color: ${(props) => props.theme.borderColor};
        width: 100%;
    }
`;

export const DropzoneExtended = styled.div`
    border: dashed 1px ${(props) => props.theme.primaryDarkMode};
    background-color: ${(props) => props.theme.white};
    padding: 0px;
    display: flex;
    flex-direction: ${(props) => props.direction || "row"};
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
    p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: ${(props) => props.theme.primaryDarkMode};
    }

    .dzu-dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 120px;
        overflow: auto;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        transition: all 0.15s linear;
        border: none;
        border-radius: 4px;
        padding-bottom: 20px;
    }

    .dzu-dropzoneActive {
        background-color: #deebff;
        border-color: #2484ff;
    }

    .dzu-dropzoneDisabled {
        opacity: 0.5;
    }

    .dzu-dropzoneDisabled *:hover {
        cursor: unset;
    }

    .dzu-input {
        display: none;
    }

    .dzu-inputLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: ${(props) => props.theme.primaryDarkMode};
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    }

    .dzu-inputLabelWithFiles {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        padding: 0 14px;
        min-height: 32px;
        background-color: #e6e6e6;
        color: #2484ff;
        border: none;
        font-family: "Helvetica", sans-serif;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
        margin-left: 3%;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    }

    .dzu-previewContainer {
        padding: 40px 3%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        min-height: 60px;
        z-index: 1;
        border-bottom: 1px solid ${(props) => props.theme.borderColor};
        box-sizing: border-box;
    }

    .dzu-previewStatusContainer {
        display: flex;
        align-items: center;
    }

    .dzu-previewFileName {
        font-family: "Helvetica", sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: ${(props) => props.theme.primaryDark};
    }

    .dzu-previewImage {
        width: auto;
        max-height: 40px;
        max-width: 140px;
        border-radius: 4px;
    }

    .dzu-previewButton {
        background-size: 14px 14px;
        background-position: center;
        background-repeat: no-repeat;
        width: 14px;
        height: 14px;
        cursor: pointer;
        opacity: 0.9;
        margin: 0 0 2px 10px;
        filter: brightness(3);
    }

    .dzu-submitButtonContainer {
        margin: 24px 0;
        z-index: 1;
    }

    .dzu-submitButton {
        padding: 0 14px;
        min-height: 32px;
        background-color: #2484ff;
        border: none;
        border-radius: 4px;
        font-family: "Helvetica", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    }

    .dzu-submitButton:disabled {
        background-color: #e6e6e6;
        color: #333333;
        cursor: unset;
    }
    .counter {
        position: absolute;
        bottom: 100%;
        left: 0;
        margin-bottom: 10px;
        font-size: 14px;
        color: ${(props) => props.theme.text};
    }
`;

export const Item = styled.div`
    background-color: ${(props) => props.theme.grayLight};
    padding: 20px;
    border-radius: 5px;
    border: solid 1px ${(props) => props.theme.borderColor};
    margin-bottom: 20px;
`;

export const NewItem = styled(Item)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: ${(props) => props.theme.primaryDarkMode};
    font-size: 14px;
    cursor: pointer;
    svg {
        width: 15px;
        color: ${(props) => props.theme.primaryDarkMode};
        opacity: 0.2;
    }
    &:hover {
        svg {
            opacity: 0.5;
        }
    }
`;

export const ButtonsExtended = styled(Buttons)`
    padding-top: 50px;
    a + a {
        margin-left: 10px;
    }
    button:disabled {
        background-color: #f3f5f6;
        color: #7a7a7a;
        cursor: not-allowed;
    }
`;

export const FieldExtended = styled(Field)`
    @media (min-width: 769px) {
        width: ${(props) => props.size}px;
    }
    img {
        max-width: 100px;
        height: auto;
        width: 100%;
    }
`;

export const ItemDetail = styled.div`
display: flex;
align-items: center;
  .image {
    flex: none;
    width: 100px;
    height: 100px;
    background-color: ${(props) => props.theme.white};
    padding: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  .text {
    width: 100%;
    padding-left: 20px;
    h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .attr {
        font-size: 12px;
        .label {
            font-weight: bold;
        }
        div + div {
            margin-top: 5px;
        }
    }
  }
`;

export const ColumnExtended = styled(Column)`
  @media (min-width: 769px) {
    max-width: 50%;
  }
`;

export const ModalExtended = styled(Modal)`
  z-index: 999;
`;

export const Product = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    border: solid 1px transparent;
    padding: 10px;
    border-radius: 3px;
    user-select: none;
    &:hover {
        border-color: ${(props) => props.theme.borderColor};
    }
    .checkbox {
        flex: none;
        padding-right: 10px;
    }
    .img {
        flex: none;
        img {
        width: 60px;
        height: 60px;
        }
    }
    .text {
        .product-title {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px;
        }
        .product-details {
        color: ${(props) => props.theme.gray};
        font-size: 12px;
        span + span {
            margin-left: 10px;
        }
        }
    }
`;

export const CheckBoxExtended = styled(Checkbox)`
    span {
        width: 29px;
        height: 29px;
        position: relative;
        display: block;
        &:before {
        content: '';
            width: 6px;
            height:12px;
            border-bottom: solid 2px white;
            border-right: solid 2px white;
            display: block;
            position: absolute;
            left: 9px;
            top: 4px;
            transform: rotate(45deg);
        }
    }
`;


export const MoreInfo = styled.a`
  cursor: pointer;
  float: right;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;