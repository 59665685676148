import React from "react";
import { Helmet } from "react-helmet";
import Blueberry from "../../blueberry";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connectPromise";
import Logo from "../components/Logo";
import Icon from "../components/Icon";
import { Button } from "../sdk/Button";
import Input from "../components/Input";
import Tabs from "../components/Tabs";
import helpers from "./helpers/index";
import { Container, Columns, Column, Title } from "../sdk/Layout";
import { Field, FieldBody, Control } from "../sdk/Form";
import { Preloader } from "../sdk/Elements";
import {
    getParameterByNameNoReplace,
    isEmailValid,
    numberWithCommas,
} from "../utils";
import {
    Cols,
    Right,
    Left,
    Wrapper,
    Header,
    Block,
    LogoWrapper,
    CartWrapper,
    InformationWrapper,
    CartInfoWrapper,
    BreadcrumsExtended,
    HeaderWrapper,
    SectionWrapper,
    ActionBack,
    OrderSummarySections,
    OrderSummarySection,
    OrderSummarySectionContent,
    RadioExtended,
    LoadingWrapper,
} from "./CheckoutStyling";

// import Discount from "./Discount";

import AddressForm from "./AddressForm";
import CartTable from "./components/CartTable";
import Listener from "../components/Listener";

const {
    doFieldChange,
    getCountriesIsoCode,
    getNextStepRoute,
    isFieldValid,
    populateFormFromStorage,
    validateFormFields,
} = helpers;

class Checkout extends MainTemplate {
    constructor(props) {
        super(props);

        const userEmail =
            props.store.user && props.store.user.id && props.store.user.emails
                ? props.store.user.emails[0].address
                : "";

        this.state = {
            formControls: {
                firstName: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                lastName: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                address1: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                address2: {
                    value: "",
                    valid: true,
                    touched: false,
                    validationRules: {
                        isRequired: false,
                    },
                },
                contactPhoneNumber: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                email: {
                    value: userEmail,
                    valid: userEmail != null && userEmail !== "" ? true : false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                        isEmail: true,
                    },
                },
                country: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                region: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
                city: {
                    value: "",
                    valid: false,
                    touched: false,
                    validationRules: {
                        minLength: 3,
                        isRequired: true,
                    },
                },
            },
            calculations: {
                total: 0,
                totalCubicaje: 0,
            },
            selectedAddress: 0,
            shippingConfig: {},
            nextButtonText: this._t("continueWithShipping"),
            isLoading: false,
            isResumenOpen: false,
        };
        this._handleFieldChange = doFieldChange.bind(this);

        this._populateFormFromStorage = populateFormFromStorage.bind(this);
    }

    componentDidMount() {
        // Populated form if data exists from next steps
        this._populateFormFromStorage("ntx-order-token");

        // Get available shipping countries
        this._getShippingConfig();

        analytics.track("Quotation Started", {
            total: this.props.store.stats.total,
        });
    }

    componentDidUpdate(prevProps, prevState) {}

    _getShippingConfig = () => {
        const { shippingConfig } = this.state;

        this.setState({
            isRefreshing: true,
        });

        Blueberry.profileForPayment()
            .then((profile) => {
                if (
                    profile &&
                    profile.addresses &&
                    profile.addresses.length > 0
                ) {
                    this.setState({
                        profile,
                        selectedAddress:
                            profile.defaultShipping != null &&
                            profile.defaultShipping >= 0
                                ? profile.defaultShipping + 1
                                : 0,
                    });
                }
            })
            .catch((err) => {});

        // if (!shippingConfig || Object.keys(shippingConfig).length === 0)
        if (true)
            Blueberry.shippingConfiguration()
                .then(({ countries, skipPayment, skipShipping, ...rest }) => {
                    const countryCodes = getCountriesIsoCode(countries);
                    this.setState({
                        shippingConfig: {
                            countries: countryCodes,
                            skipPayment,
                            skipShipping,
                            ...rest,
                        },
                        nextButtonText: this._renderNextButtonText(
                            skipPayment,
                            skipShipping
                        ),
                        isRefreshing: false,
                    });
                })
                .catch((err) => {
                    console.log(
                        "Error getting available shipping countries",
                        err
                    );
                    this.setState({
                        isRefreshing: false,
                    });
                });
    };

    getCartIds = () => {
        const { search } = this.props.location;
        const cartIds = getParameterByNameNoReplace("cartIds", search);
        return cartIds ? cartIds.split(",") : [];
    };

    saveOrderToLocalStorage = (order) => {
        const orderCopy = { ...order };
        orderCopy.items = this.props.cart.items;
        localStorage.setItem("ntx-order-token", JSON.stringify(orderCopy));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { lang } = this.props.match.params;
        const {
            formControls,
            shippingConfig: { skipShipping, skipPayment },
            selectedAddress,
            profile,
        } = this.state;
        const form = validateFormFields(formControls);

        if (
            (form.hasErrors && selectedAddress <= 0) ||
            (selectedAddress > 0 && !isEmailValid(formControls.email.value))
        ) {
            this.setState({ formControls: form.formControls });
            return;
        }

        this.setState({ isUpdating: true, hasError: false, success: false });

        const isAQuote = skipPayment && skipShipping;

        const data = isAQuote
            ? {
                  fields: form.formData,
                  quotation: true,
                  cartIds: this.getCartIds(),
              }
            : {
                  fields: form.formData,
                  cartIds: this.getCartIds(),
              };
        if (
            selectedAddress > 0 &&
            profile &&
            profile.addresses &&
            profile.addresses.length > 0
        ) {
            console.log(
                "using selected data",
                profile.addresses[selectedAddress]
            );
            data.fields = {
                ...data.fields,
                ...profile.addresses[selectedAddress],
            };
        }

        Blueberry.orderShipping(data)
            .then((order) => {
                this.setState({ isUpdating: false, success: true });
                const tokens = order.map((index) => index.public_token);

                const route = getNextStepRoute(
                    lang,
                    skipPayment,
                    skipShipping,
                    tokens.join(",")
                );

                if (isAQuote) {
                    analytics.track("Quotation Completed", {
                        total: this.props.store.stats.total,
                    });
                } else if (!isAQuote) {
                    this.saveOrderToLocalStorage(order);
                }

                this.props.history.push(route);
            })
            .catch((err) => {
                console.log("Error setting order shipping address", err);
                this.setState({ hasError: true, isUpdating: false });
            });
    };

    applyDiscount = (e) => {
        // TODO Definir proceso para aplicar cupon de descuento
        e.preventDefault();
        e.stopPropagation();
        return null;
    };

    setSelectedAddress = (e) => {
        this.setState({
            selectedAddress: parseInt(e.target.value),
        });
    };

    renderHeader = () => {
        return null;
    };

    renderFooter = () => {
        return null;
    };

    _renderNextButtonText = (skipPayment, skipShipping) => {
        if (skipPayment && skipShipping) return this._t("continueWithQuote");
        if (skipShipping && !skipPayment)
            return this._t("continueWithPayments");

        return this._t("continueWithShipping");
    };

    toggleResumen = () => {
        this.setState((prev) => ({ isResumenOpen: !prev.isResumenOpen }));
    };

    renderBody() {
        const { email } = this.state.formControls;
        const { lang } = this.props.match.params;

        const { stats, user, cart } = this.props.store;
        const {
            shippingConfig: { countries },
            nextButtonText,
            isUpdating,
            selectedAddress,
            profile,
            isLoading,
            isRefreshing,
            isResumenOpen,
        } = this.state;

        return (
            <Wrapper className="site-page">
                <Helmet htmlAttributes={{ lang }}>
                    <title>Checkout</title>
                    <body className="reset-body" />
                </Helmet>
                <Container>
                    <Cols>
                        <Left>
                            <InformationWrapper>
                                <Header>
                                    <LogoWrapper>
                                        <Logo />
                                    </LogoWrapper>
                                    <BreadcrumsExtended
                                        crums={[
                                            {
                                                to: "/cart",
                                                title: this._t("cart"),
                                            },
                                            {
                                                to: "/checkout",
                                                title: this._t("information"),
                                            },
                                        ]}
                                    />
                                </Header>
                                {isRefreshing ? (
                                    <LoadingWrapper>
                                        <Block>
                                            <Preloader></Preloader>
                                            <Preloader half></Preloader>
                                        </Block>
                                        <Block>
                                            <Preloader></Preloader>
                                            <Preloader></Preloader>
                                            <Preloader half></Preloader>
                                        </Block>
                                    </LoadingWrapper>
                                ) : (
                                    <div>
                                        <Block>
                                            <HeaderWrapper>
                                                <h2>
                                                    1.{" "}
                                                    {this._t(
                                                        "contactInformation"
                                                    )}
                                                </h2>
                                            </HeaderWrapper>
                                            <Field>
                                                <Input
                                                    valid={isFieldValid(email)}
                                                    name="email"
                                                    autoComplete="shipping email"
                                                    ariaDescribedby="checkout-context-step-one error-for-email"
                                                    autoCapitalize="off"
                                                    spellCheck={false}
                                                    label={this._t("email")}
                                                    value={email.value}
                                                    onChange={
                                                        this._handleFieldChange
                                                    }
                                                    type="email"
                                                    id="checkout_email"
                                                />
                                            </Field>
                                        </Block>
                                        <Block>
                                            <HeaderWrapper>
                                                <h2>2. Dirección de envío</h2>
                                                {/* <h2>{this._t("shippingMethod")}</h2> */}
                                            </HeaderWrapper>
                                            <Field>
                                                {profile &&
                                                    profile.addresses &&
                                                    profile.addresses.map(
                                                        (index, i) => (
                                                            <RadioExtended
                                                                key={`addreses-${i}`}
                                                            >
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        name="importedBefore"
                                                                        value={
                                                                            i +
                                                                            1
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .setSelectedAddress
                                                                        }
                                                                        checked={
                                                                            selectedAddress ===
                                                                            i +
                                                                                1
                                                                        }
                                                                    />
                                                                    <span></span>
                                                                    <p>
                                                                        {
                                                                            index.firstName
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            index.lastName
                                                                        }
                                                                        <br />
                                                                        {
                                                                            index.address1
                                                                        }
                                                                        <br />
                                                                        {
                                                                            index.address2
                                                                        }
                                                                    </p>
                                                                </label>
                                                            </RadioExtended>
                                                        )
                                                    )}
                                            </Field>
                                            <Field>
                                                <RadioExtended>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="importedBefore"
                                                            value={0}
                                                            checked={
                                                                selectedAddress ===
                                                                0
                                                            }
                                                            onChange={
                                                                this
                                                                    .setSelectedAddress
                                                            }
                                                        />
                                                        <span></span>
                                                        {/* <p>{this._t("new")}</p> */}
                                                        <p>Nueva dirección</p>
                                                    </label>
                                                </RadioExtended>
                                            </Field>
                                            {selectedAddress === 0 ? (
                                                <Field>
                                                    {/* <Tabs
                                                        tabs={[
                                                            {
                                                                title: this._t("ship"),
                                                                selected: true,
                                                            },
                                                        ]}
                                                    /> */}
                                                    <AddressForm
                                                        countryList={countries}
                                                        onIsValid={isFieldValid}
                                                        onChange={
                                                            this
                                                                ._handleFieldChange
                                                        }
                                                        _t={this._t}
                                                        {...this.state
                                                            .formControls}
                                                    />
                                                </Field>
                                            ) : null}
                                        </Block>

                                        <HeaderWrapper>
                                            <ActionBack
                                                to="/cart"
                                                replace={true}
                                            >
                                                <Icon icon="chevron-left" />
                                                {this._t("backToCart")}
                                            </ActionBack>
                                            <Button
                                                primary
                                                onClick={this.handleSubmit}
                                                loading={isUpdating}
                                            >
                                                {nextButtonText}
                                            </Button>
                                        </HeaderWrapper>
                                    </div>
                                )}
                            </InformationWrapper>
                        </Left>
                        <Listener
                            user={user}
                            action={this._getShippingConfig}
                        />

                        <CartTable
                            isRefreshing={isRefreshing}
                            cart={cart}
                            cartsIds={this.getCartIds()}
                            isServer={this.isServer}
                            stats={stats}
                        />
                    </Cols>
                </Container>
            </Wrapper>
        );
    }
}

export default withTracker((props) => {})(Checkout);
