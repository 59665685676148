import React from "react";
import { Helmet } from "react-helmet";
import Blueberry from "../../blueberry";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connect";
import { Button } from "../sdk/Button";
import InternalLink from "../components/InternalLink";
import Logo from "../components/Logo";
import Notice from "../components/Notice";
import ProductTable from "./ProductTable";
import TotalsTable from "./TotalsTable";
import { numberWithCommas } from "../utils";
import helper from "./helpers/index";
import { Columns, Column, Container, Title } from "../sdk/Layout";

import {
    Col,
    Cols,
    Left,
    Right,
    Wrapper,
    Header,
    LogoWrapper,
    CartWrapper,
    CartInfoWrapper,
    InformationWrapper,
    HeaderColumnWrapper,
    HeaderWrapper,
    ContentBox,
    ContentTable,
    ContentRow,
    OrderSummarySections,
    OrderSummarySection,
    OrderSummarySectionContent,
    ReviewBox,
    Block,
} from "./CheckoutStyling";

const { getCheckoutMessage, hasAdditionalPayment, hasValidPaymentStatus } =
    helper;

class OrderDetails extends MainTemplate {
    paymentTypes = {
        CREDIT_CARD: this._t("CREDIT_CARD"),
        BANK_DEPOSIT: this._t("BANK_TRANSFER"),
    };

    constructor(props) {
        super(props);

        this.state = {
            order: [],
            public_token: this.props.match.params.token,
            checkoutText: "",
        };
    }

    componentDidMount() {
        const { public_token, order: stateOrder } = this.state;

        return (
            Object.keys(stateOrder).length === 0 &&
            Blueberry.shippingConfiguration()
                .then(({ checkoutText }) => {
                    Blueberry.orderByToken({
                        public_tokens: public_token.split(","),
                    }).then((order) => {
                        // console.log("Order", order);

                        this.setState({ order, checkoutText });
                    });
                })
                .catch((err) => {
                    console.log("Error getting order details", err);
                })
        );
    }

    generateKey = (pre) => {
        return `${pre}_${Math.random().toString(16).slice(-4)}`;
    };

    handlePaymentStorage = (order) => {
        if (typeof localStorage !== "undefined")
            localStorage.setItem("ntx-order-token", JSON.stringify(order));
    };

    handleClick = () => {
        const { lang } = this.props.match.params;

        this.props.history.replace(`/${lang}/`);
    };

    renderHeader = () => {
        return null;
    };

    renderFooter = () => {
        return null;
    };

    renderPayments = (payments) => {
        const filtered = payments.filter(
            (p) =>
                typeof p.amount !== "undefined" &&
                p.amount > 0 &&
                p.transactionInfo &&
                p.transactionInfo.state &&
                p.transactionInfo.state !== "DECLINED"
        );

        if (!filtered || filtered.length === 0) return null;

        return (
            <HeaderColumnWrapper margin="0" className="col-sm-6 col-xs-12">
                <HeaderWrapper>
                    <h2>Método de pago</h2>
                </HeaderWrapper>
                {filtered.map((payment) => (
                    <p key={this.generateKey(payment.type.toLowerCase())}>
                        {this.paymentTypes[payment.type]} -{" "}
                        <b>${numberWithCommas(payment.amount)}</b>
                    </p>
                ))}
            </HeaderColumnWrapper>
        );
    };

    renderAddressInfo = (title, userName, address) => {
        return (
            <HeaderColumnWrapper margin="0" className="col-sm-6 col-xs-12">
                <HeaderWrapper>
                    <h2>{title}</h2>
                </HeaderWrapper>
                <p>{userName}</p>
                <p>{address.address1}</p>
                <p>{address.address2 || null}</p>
                <p>{address.region}</p>
                <p>{address.city}</p>
                <p>{address.country}</p>
                <p>{address.contactPhoneNumber}</p>
            </HeaderColumnWrapper>
        );
    };

    renderPaymentNotice = (order) => {
        console.log("here");
        const isAdditional = hasAdditionalPayment(order);
        if (hasValidPaymentStatus(order)) {
            const content = (
                <>
                    <p>
                        {isAdditional
                            ? this._t("orderNeedsAdditionalPayment")
                            : this._t("orderReadyToPayment")}
                    </p>
                    <InternalLink
                        type="button"
                        to={{
                            pathname: `/orders/${order.public_token}/payment`,
                            state: { fromOrderDetails: true },
                        }}
                        onClick={this.handlePaymentStorage(order)}
                    >
                        {this._t("payNow")}
                    </InternalLink>
                </>
            );
            return (
                <Notice
                    className="cta"
                    content={content}
                    isVisible
                    type={isAdditional ? "WARNING" : "INFO"}
                />
            );
        }

        return null;
    };

    renderOrderConfirm = (order, checkoutText) => {
        if (checkoutText === "" || checkoutText == null) return null;
        return (
            <ReviewBox>
                <ContentBox>
                    <ContentTable>
                        <ContentRow noBorder>
                            <HeaderColumnWrapper margin="1em">
                                <HeaderWrapper>
                                    <h2>{this._t("orderConfirmed")}</h2>
                                </HeaderWrapper>
                                <p>
                                    {getCheckoutMessage(
                                        order,
                                        checkoutText,
                                        this._t("orderConfirmedNextStep")
                                    )}
                                </p>
                            </HeaderColumnWrapper>
                        </ContentRow>
                    </ContentTable>
                </ContentBox>
            </ReviewBox>
        );
    };

    renderBody() {
        const { lang } = this.props.match.params;
        const { order, checkoutText } = this.state;

        const firstOrder = order.length > 0 ? order[0] : {};

        const { shippingAddress, billing: billingAddress } = firstOrder;

        const { payment } = firstOrder;

        const { logs: payments } = payment || [];

        const total = order.reduce((prev, next) => prev + next.total, 0);

        return (
            <Wrapper className="site-page">
                <Helmet htmlAttributes={{ lang }}>
                    <title>Checkout / Gracias</title>
                    <body className="reset-body" />
                </Helmet>
                <Container>
                    <Cols>
                        <Left>
                            <InformationWrapper>
                                <Header>
                                    <LogoWrapper>
                                        <Logo />
                                    </LogoWrapper>
                                </Header>
                                <Title size="5">
                                    Gracias{" "}
                                    {shippingAddress &&
                                        shippingAddress.firstName}
                                    !
                                </Title>
                                {hasAdditionalPayment(firstOrder) ||
                                hasValidPaymentStatus(firstOrder)
                                    ? this.renderPaymentNotice(firstOrder)
                                    : this.renderOrderConfirm(
                                          firstOrder,
                                          checkoutText
                                      )}
                                <ReviewBox style={{ marginTop: "1em" }}>
                                    <ContentBox>
                                        <ContentTable>
                                            <Block>
                                                <HeaderColumnWrapper
                                                    margin="0"
                                                    className="col-sm-6 col-xs-12"
                                                >
                                                    <HeaderWrapper>
                                                        <h2>
                                                            {this._t(
                                                                "clientInformation"
                                                            )}
                                                        </h2>
                                                    </HeaderWrapper>
                                                    <p>
                                                        {order && order.email}
                                                    </p>
                                                </HeaderColumnWrapper>
                                                {payments &&
                                                    this.renderPayments(
                                                        payments
                                                    )}
                                            </Block>
                                            <Block>
                                                {shippingAddress &&
                                                    this.renderAddressInfo(
                                                        this._t(
                                                            "shippingAddress"
                                                        ),
                                                        firstOrder.userName,
                                                        shippingAddress
                                                    )}
                                                {billingAddress &&
                                                    this.renderAddressInfo(
                                                        this._t(
                                                            "billingAddress"
                                                        ),
                                                        firstOrder.userName,
                                                        billingAddress
                                                    )}
                                            </Block>
                                            {firstOrder.status &&
                                            firstOrder.status !==
                                                "QUOTATION" ? (
                                                <Block>
                                                    <HeaderColumnWrapper margin="0 1em">
                                                        <h5>
                                                            {this._t(
                                                                "shippingMethod"
                                                            )}
                                                        </h5>
                                                        {firstOrder.shippingRate ? (
                                                            <p>
                                                                {
                                                                    firstOrder
                                                                        .shippingRate
                                                                        .name
                                                                }
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                {this._t(
                                                                    "contactForShipping"
                                                                )}
                                                            </p>
                                                        )}
                                                    </HeaderColumnWrapper>
                                                </Block>
                                            ) : null}
                                        </ContentTable>
                                    </ContentBox>
                                </ReviewBox>
                                <HeaderWrapper>
                                    <Button onClick={this.handleClick}>
                                        {this._t("gotoHome")}
                                    </Button>
                                </HeaderWrapper>
                            </InformationWrapper>
                        </Left>
                        <Right>
                            <CartWrapper>
                                <CartInfoWrapper className="row">
                                    <div className="col-sm-8 col-xs-12">
                                        <OrderSummarySections>
                                            <HeaderWrapper>
                                                <h2>
                                                    {this._t("orderSummary")}
                                                </h2>
                                            </HeaderWrapper>
                                            <OrderSummarySection className="product-list">
                                                <OrderSummarySectionContent>
                                                    <ProductTable
                                                        cart={order}
                                                        cartsIds={order.map(
                                                            (index) => index._id
                                                        )}
                                                        _t={this._t}
                                                    />
                                                </OrderSummarySectionContent>
                                            </OrderSummarySection>
                                            <OrderSummarySection>
                                                <TotalsTable
                                                    _t={this._t}
                                                    total={total}
                                                    taxes={0}
                                                    step="finished"
                                                    shipping={0}
                                                />
                                            </OrderSummarySection>
                                        </OrderSummarySections>
                                    </div>
                                </CartInfoWrapper>
                            </CartWrapper>
                        </Right>
                    </Cols>
                </Container>
            </Wrapper>
        );
    }
}

export default withTracker()(OrderDetails);
