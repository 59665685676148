import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AsideMenu, MenuLabel, MenuList } from "../../sdk/AsideMenu";
import { useTranslation } from "../../../lang";
import Icon from "../../components/Icons2";
import InternalLink from "../../components/InternalLink";

function Menu({ user }) {
	const [menu, setMenu] = useState(true);
	const [_t] = useTranslation();
	const toggleMenu = () => {
		setMenu(!menu);
	};

	return (
		<>
			<AsideMenu>
				<MenuLabel onClick={() => toggleMenu()}>
					{_t("myAccount")}
					<Icon />
				</MenuLabel>

				<MenuList className={menu ? "is-open" : ""}>
					<li>
						<InternalLink
							activeClassName="active"
							to="/account/profile">
							<Icon icon="user" />

							{_t("profile")}
						</InternalLink>
					</li>
					<li>
						<InternalLink
							activeClassName="active"
							to="/account/store">
							<Icon icon="store" />

							{_t("store")}
						</InternalLink>
					</li>
					<li>
						<InternalLink
							activeClassName="active"
							to="/account/security">
							<Icon icon="lock" />

							{_t("security")}
						</InternalLink>
					</li>
					{user &&
					user.roles &&
					user.roles.findIndex((rol) => rol === "read-accounts") >=
						0 ? (
						<li>
							<InternalLink
								activeClassName="active"
								to="/account/reports">
								<Icon icon="file" />

								{_t("reports")}
							</InternalLink>
						</li>
					) : null}
				</MenuList>
			</AsideMenu>
		</>
	);
}

export default Menu;
