import Blueberry from "../../../blueberry";

const invalidStatuses = ["QUOTATION", "CREDIT_CARD", "PENDING_MANUAL_REVIEW"];

export const getCheckoutMessage = (order, messages, defaultMessage) => {
	if (!messages) return "";

	const checkout = messages.filter(m => m.status === order.status);
	if (checkout && checkout.length > 0)
		return checkout[0].message;

	return defaultMessage;
}

export async function getOrderFromAPI(token) {
	await Blueberry.orderByToken({token})
		.then((order) => {
			console.log("Order:", order)
			return order;
		})
		.catch(err => {
			console.log("Error getting order details", err);
			return null;
		});
}

export function getOrderFromStorage() {
	let config = { calculations: {}, public_token: "", shippingAddress: {}, shippingRate: {} };
  
	if (typeof localStorage !== "undefined") {
		const order = JSON.parse(localStorage.getItem("ntx-order-token"));
    
		if (!order) return config;
    
		const calculations = {
			total: order && order.total ? order.total : 0,
			subTotal: order && order.subTotal ? order.subTotal : 0,
			tax: order && order.tax ? order.tax : 0,
			paidAmount: order && order.paidAmount ? order.paidAmount : 0,
			difAmount: order && order.difAmount ? order.difAmount : 0
		}
		config = {
			calculations,
			cartItems: order.items,
			public_token: order.public_token || "",
			shippingAddress: order.shippingAddress || {},
			shippingRate: order.shippingRate || {},
		};
	}

	return config;
}

export function hasAdditionalPayment(order) {
	return order && order.difAmount && order.paidAmount && order.difAmount > 0 && order.paidAmount > 0;
}

export function hasValidPaymentStatus(order) {
	return order && order.status && order.difAmount && !invalidStatuses.includes(order.status) && order.difAmount > 0;
}