import React, { Component } from "react";
import styled from "styled-components";
import { Modal } from "../sdk/Elements";
import ModalPortal from "./ModalPortal";
import { Button } from "../sdk/Button";
import { Box } from "../sdk/Layout";

const ModalExtended = styled(Modal)`
    z-index: 999;
`;

// App component - represents the whole app
export default class ModalComponent extends Component {
    nothing = (e) => {
        // e.preventDefault();
        e.stopPropagation();
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.onClose();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible && this.props.visible) {
            this.disableScrolling();
            return;
        }

        this.enableScrolling();
    }

    componentWillUnmount() {
        this.enableScrolling();
        document.removeEventListener("keydown", this.escFunction, false);
    }

    disableScrolling() {
        var d = document.getElementById("main-body");
        d.className += " ReactModal__Body--open";
    }

    enableScrolling() {
        var d = document.getElementById("main-body");
        d.className = d.className.replace(/\ReactModal__Body--open\b/g, "");
    }

    onClose = () => {
        this.enableScrolling();
        this.props.close();
    };

    render() {
        return (
            <ModalPortal>
                <ModalExtended className={this.props.visible && "is-active"}>
                    <Button onClick={this.onClose}></Button>
                    <span onClick={this.onClose} />
                    <Box>{this.props.children}</Box>
                </ModalExtended>
            </ModalPortal>
        );
    }
}
