import React from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PropTypes, { string } from "prop-types";
import Input from "../components/Input";
import { InputWrapper, SectionFill } from "./CheckoutStyling";

export default function AddressForm({
    firstName,
    lastName,
    address1,
    address2,
    country,
    countryList,
    region,
    city,
    contactPhoneNumber,
    _t,
    onIsValid,
    onChange,
}) {
    return (
        <SectionFill>
            <div className="row">
                <div className="is-half">
                    <Input
                        valid={onIsValid(firstName)}
                        name="firstName"
                        label={_t("firstName")}
                        value={firstName.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping given-name"
                    />
                </div>
                <div className="is-half">
                    <Input
                        valid={onIsValid(lastName)}
                        name="lastName"
                        label={_t("lastName")}
                        value={lastName.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping family-name"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <Input
                        valid={onIsValid(address1)}
                        name="address1"
                        label={_t("directionSend")}
                        value={address1.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping address-line1"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <Input
                        valid={onIsValid(address2)}
                        name="address2"
                        label={_t("directionSend2")}
                        value={address2.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping address-line2"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <Input
                        valid={onIsValid(city)}
                        name="city"
                        label={_t("city")}
                        value={city.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping address-level2"
                    />
                </div>
            </div>
            <div className="row">
                <div className="is-half">
                    <InputWrapper valid={onIsValid(country)}>
                        <label htmlFor="country">{_t("country")}</label>
                        <CountryDropdown
                            className={`form-control ${onIsValid(country)}`}
                            value={country.value}
                            name="country"
                            id="country"
                            whitelist={countryList}
                            onChange={(val) =>
                                onChange({
                                    target: { value: val, name: "country" },
                                })
                            }
                            key={
                                countryList && countryList.length > 0
                                    ? countryList[0]
                                    : ""
                            }
                        />
                    </InputWrapper>
                </div>
                <div className="is-half">
                    <InputWrapper valid={onIsValid(region)}>
                        <label htmlFor="region">{_t("region")}</label>
                        <RegionDropdown
                            country={country.value}
                            name="region"
                            id="region"
                            value={region.value}
                            onChange={(val) =>
                                onChange({
                                    target: { value: val, name: "region" },
                                })
                            }
                        />
                    </InputWrapper>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <Input
                        valid={onIsValid(contactPhoneNumber)}
                        name="contactPhoneNumber"
                        label={_t("contactPhoneNumber")}
                        value={contactPhoneNumber.value}
                        onChange={onChange}
                        type="text"
                        autoComplete="shipping tel"
                    />
                </div>
            </div>
        </SectionFill>
    );
}

const fieldShape = {
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired,
    validationRules: PropTypes.shape({
        minLength: PropTypes.number,
        isRequired: PropTypes.bool,
    }),
};

AddressForm.propTypes = {
    firstName: PropTypes.shape(fieldShape).isRequired,
    lastName: PropTypes.shape(fieldShape).isRequired,
    address1: PropTypes.shape(fieldShape).isRequired,
    address2: PropTypes.shape(fieldShape),
    country: PropTypes.shape(fieldShape).isRequired,
    countryList: PropTypes.arrayOf(string),
    region: PropTypes.shape(fieldShape).isRequired,
    city: PropTypes.shape(fieldShape).isRequired,
    contactPhoneNumber: PropTypes.shape(fieldShape).isRequired,
    _t: PropTypes.func.isRequired,
    onIsValid: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

AddressForm.defaultProps = {
    address2: "",
    countryList: [],
};
