import React from "react";
import { useTranslation } from "../../../lang";

import { Preloader } from "../../sdk/Elements";
import { numberWithCommas } from "../../utils";
import {
    Right,
    Block,
    CartWrapper,
    CartInfoWrapper,
    HeaderWrapper,
    OrderSummarySections,
    OrderSummarySection,
    OrderSummarySectionContent,
    LoadingWrapper,
} from "../CheckoutStyling";
import ProductTable from "../ProductTable";
import TotalsTable from "../TotalsTable";

function CartTable({ cart, isServer, stats, isRefreshing, cartsIds }) {
    const [_t] = useTranslation();
    const [isToggle, setIsToggle] = React.useState(false);

    const cartsIdsArray = cartsIds;

    return (
        <Right
            className="col-sm-6 col-xs-12 overflow-hidden"
            role="complementary"
            aria-label="Resumen del Pedido"
        >
            {isRefreshing ? (
                <LoadingWrapper>
                    <Block>
                        <Preloader half></Preloader>
                    </Block>
                    <Block>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                        <Preloader></Preloader>
                    </Block>
                    <Block>
                        <Preloader half></Preloader>
                        <Preloader half></Preloader>
                        <Preloader></Preloader>
                    </Block>
                </LoadingWrapper>
            ) : (
                <CartWrapper>
                    <CartInfoWrapper className="row">
                        <div className="col-sm-8 col-xs-12">
                            <OrderSummarySections>
                                <HeaderWrapper
                                    onClick={() => setIsToggle((prev) => !prev)}
                                    tabIndex="0"
                                >
                                    <div>
                                        <h2>
                                            {_t("orderSummary")}
                                            <span className="down-arrow show-on-mobile"></span>
                                        </h2>
                                    </div>
                                    <div className="show-on-mobile">
                                        <p>
                                            USD {numberWithCommas(stats.total)}
                                        </p>
                                    </div>
                                </HeaderWrapper>
                                <div
                                    className={
                                        !isToggle ? "hide-on-mobile" : ""
                                    }
                                >
                                    <OrderSummarySection className="product-list">
                                        <OrderSummarySectionContent>
                                            <ProductTable
                                                cart={cart}
                                                cartsIds={cartsIdsArray}
                                                _t={_t}
                                                isServer={isServer}
                                            />
                                        </OrderSummarySectionContent>
                                    </OrderSummarySection>
                                    {/* <OrderSummarySection>
                                                        <Discount name="discountCode" _t={_t} applyDiscount={this.applyDiscount} />
                                                    </OrderSummarySection> */}
                                    <OrderSummarySection>
                                        <TotalsTable
                                            _t={_t}
                                            total={stats.total}
                                            taxes={0}
                                        />
                                    </OrderSummarySection>
                                </div>
                            </OrderSummarySections>
                        </div>
                    </CartInfoWrapper>
                </CartWrapper>
            )}
        </Right>
    );
}

export default CartTable;
