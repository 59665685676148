/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React from "react";
import { Title, Box, Spacer } from "../../../sdk/Layout";
import { Buttons, Button } from "../../../sdk/Button";
import { Preloader, Message } from "../../../sdk/Elements";
import {
	Content,
	ButtonsExtended,
	Steps,
	NewItem,
	ModalExtended,
} from "../style";
import { Field, Control } from "../../../sdk/Form";
import Icons from "./Icons";
import Blueberry from "../../../../blueberry";
import ProductRow from "./ProductRow";
import useQueryString from "../../../hooks/useQueryString";
import GridItem from "./GridItem";

function ProductsForClaim({
	getUploadParams,
	handleChangeStatus,
	grid,
	clearInitialImagesDropzone,
	handleSubmitForm,
	handleAddNewGridRow,
	handleRemoveGridRow,
	setValueGrid,
	setGrid,
	isUpdating,
	langJson,
	_t,
	failureDetails,
}) {
	const [modal, setModal] = React.useState(false);
	const [data, setData] = React.useState([]);
	const [searchData, setSearchData] = React.useState([]);
	const [newRow, setNewRow] = React.useState([]);
	const [isLoading, setLoading] = React.useState(false);
	const [order] = useQueryString("order", null);
	const [companyId] = useQueryString("companyId", "");
	const [claimId] = useQueryString("claimId", "");

	const getProduct = (products, reference) => products.find((e) => (e.sku || e.slug) === reference);

	const getMergedItems = (items, products) => {
		const merged = [];
		items.forEach((i) => {
			const product = getProduct(products, i.slug);
			merged.push({ ...i, product });
		});

		return merged;
	};

	const removeItemsFromSearch = (items, list) => {
		const removedItems = items;
		list.forEach((e) => {
			const index = removedItems.findIndex((l) => l.slug === (e.sku || e.slug));
			removedItems.splice(index, 1);
		});
		return removedItems;
	};

	const handleSearch = (e) => {
		const search = e.target.value.toLowerCase();
		if (search.length > 2) {
			const regex = new RegExp(search, "gi");
			const results = data.filter((d) => d.slug.search(regex) >= 0
                || d.product?.title?.search(regex) >= 0
                || d.product?.title_eng?.search(regex) >= 0);
			setSearchData(results);
			return;
		}

		setSearchData(data);
	};

	React.useEffect(() => {
		// Buscar resto de productos del pedido
		Blueberry.orderFromERP({
			companyId, documentNumber: order, includeProducts: true, typeOfRequest: "billById",
		})
			.then((resp) => {
				const merged = getMergedItems(resp.result.items, resp.products);
				setData(merged);
				setSearchData(removeItemsFromSearch(merged, grid));
			})
			.catch((err) => {
				console.error("error...", err);
			});

		// Mostrar loading al cargar los items del grid en modo edicion
		if (claimId) setLoading(true);
		if (claimId && grid.length > 0) setLoading(false);
	}, [grid]);

	const checkMotive = (field, motive, pending, index) => {
		let isError = false;
		if (motive === "missing") {
			isError = (field.checked && field.value < 1);
		}

		if (motive !== "missing" && field.checked && (field.value < 1
            || (field.images.length < ((motive === "broken" || motive === "poor quality") ? field.value : 1)
                && field.images.length < 5)
		)) {
			isError = true;
		}

		if (isError) {
			if (pending[index]) pending[index].push(motive);
			// eslint-disable-next-line no-param-reassign
			else pending[index] = [motive];
		}
	};

	const handleValidImagesBeforeSubmition = (e) => {
		const pending = [];
		const itemReady = [];
		for (let i = 0; i < grid.length; i++) {
			const item = grid[i];
			pending[i] = "";

			// Si la descripcion esta vacia, entonces no se ha tocado el item (error)
			if (!item.description) {
				pending[i] = "default";
				continue;
			}

			// Si el motivo esta seleccionado pero no se ha ingresado la cantidad o es 0 (error)
			const zero = item.description.some((desc) => desc.checked && desc.value < 1);
			if (zero) {
				pending[i] = "default";
				continue;
			}

			// Continuar y verificar cada uno de los motivos
			const broken = item.description[0];
			const quality = item.description[1];
			const missing = item.description[2];
			const surplus = item.description[3];
			const bulk = item.description[4];
			const wrong = item.description[5];

			// Si no se le ha dado check a ningun motivo (error)
			if (
				!broken.checked && !quality.checked && !missing.checked && !surplus.checked
				&& !bulk.checked && !wrong.checked
			) {
				pending[i] = "default";
				continue;
			}

			// Validar cantidad de reportados vs facturado
			const group = (broken.checked && Number(broken.value))
				+ (quality.checked && Number(quality.value))
				+ (missing.checked && Number(missing.value))
				+ (wrong.checked && Number(wrong.value));
			if (group > Number(item.quantity)) {
				pending[i] = "overflow";
				continue;
			}

			// Validar cada uno de los motivos segun reglas de negocio
			checkMotive(broken, "broken", pending, i);
			checkMotive(quality, "poor quality", pending, i);
			checkMotive(missing, "missing", pending, i);
			checkMotive(surplus, "surplus", pending, i);
			checkMotive(bulk, "bulk", pending, i);
			checkMotive(wrong, "wrong", pending, i);

			if (!pending[i]) itemReady[i] = true;
		}

		// Submit form si esta todo ok
		if (!pending.filter((val) => !!val).length) {
			handleSubmitForm(e);
		} else {
			const indexErr = pending.findIndex((val) => !!val);
			const pendingGrid = [...grid];
			pending.map((value, index) => {
				pendingGrid[index].pending = value;
				pendingGrid[index].isReady = !!itemReady[index];
				return pendingGrid;
			});
			setGrid(pendingGrid);
			window.scrollTo({
				top: document.getElementById(`grid-item-${indexErr}`).offsetTop,
				left: 0,
				behavior: "smooth",
			});
		}
	};

	const handleAddingNewRow = (e, item) => {
		const row = [...newRow];

		if (e.target.checked) {
			const {
				slug, quantity, description = "", images = [], price = "",
			} = item;

			const title = item.product?.title || item.product?.title_eng || "---";
			const thumb = item.product?.imagesUrl[0].thumb || "";
			const multiple = item.product?.multiple || "";

			row.push({
				sku: slug,
				quantity: quantity.toString(),
				description,
				images,
				title,
				thumb,
				price: price.toString(),
				multiple: multiple.toString(),
			});
			setNewRow(row);
			return;
		}

		// remove item
		const index = row.findIndex((r) => r.slug === item.slug);
		if (index >= 0) {
			row.splice(index, 1);
			setNewRow(row);
		}
	};

	const handleAcceptNewRows = (e) => {
		handleAddNewGridRow(e, newRow);
		setSearchData(removeItemsFromSearch(searchData, newRow));
		setNewRow([]);
		setModal(false);
	};

	if (isLoading) {
		return (
			<>
				<Preloader />
				<Preloader />
				<Preloader />
			</>
		);
	}

	return (
		<>
			<Content>
				<Steps>
					<div className="is-completed">1</div>
					<span />
					<div className="is-active">2</div>
					<span />
					<div>
						<Icons icon="check" />
					</div>
				</Steps>
				<Title as="h2" size="5" primary>
					{langJson.productTitle}
				</Title>

				{grid.map((index, i) => (
					<GridItem
						id={`grid-item-${i}`}
						key={`grid-item-${index.sku}`}
						row={index}
						_t={_t}
						rowNumber={i}
						langJson={langJson}
						setValueGrid={setValueGrid}
						getUploadParams={getUploadParams}
						handleChangeStatus={handleChangeStatus}
						handleRemoveGridRow={handleRemoveGridRow}
						clearInitialImagesDropzone={clearInitialImagesDropzone}
						failureDetails={failureDetails}
					/>
				))}

				<NewItem onClick={() => setModal(true)}>
					{langJson.addAnother}
					<Icons icon="plus" />
				</NewItem>

				{
					failureDetails && (
						<>
							<Spacer />
							<Message error>
								{langJson.claimCustomError[failureDetails.customError]}
							</Message>
						</>
					)
				}

				<ButtonsExtended isRight>
					<Button
						primary
						onClick={handleValidImagesBeforeSubmition}
						loading={isUpdating}
						disabled={grid.length === 0}
					>
						{langJson.sendToMyRepresentative}
					</Button>
				</ButtonsExtended>
			</Content>

			{modal && (
				<ModalExtended className="is-active">
					<button onClick={() => setModal(false)} type="button">&nbsp;</button>
					<div>
						<Box>
							<Title as="h3" size="5">{langJson.addingTitle}</Title>
							<Field>
								<Control isExpanded>
									<input
										type="text"
										placeholder={langJson.addingPlaceholder}
										onChange={handleSearch}
									/>
								</Control>
							</Field>

							<Spacer />

							<div style={{ overflowY: "scroll", maxHeight: "450px" }}>
								{
									searchData.map((i) => (
										<ProductRow key={i.slug} item={i} addNewRow={(e) => handleAddingNewRow(e, i)} />
									))
								}
							</div>

							<Spacer />
							<Buttons isRight onClick={handleAcceptNewRows}>
								<Button primary>{langJson.addingButton}</Button>
							</Buttons>
						</Box>
					</div>
					<span />
				</ModalExtended>
			)}
		</>
	);
}

export default ProductsForClaim;
