import React from "react";
import { Container, Section, Title, Heading } from "../../../sdk/Layout";
import { Button } from "../../../sdk/Button";
import { Content, ButtonsExtended, Steps } from "../style";
import Icons from "./Icons";
import InternalLink from "../../../components/InternalLink";

function Final({ handleNextStep, langJson, brandSlug }) {
    return (
        <Content>
            <Steps>
                <div className="is-completed">1</div>
                <span></span>
                <div className="is-completed">2</div>
                <span></span>
                <div className="is-active">
                    <Icons icon="check"></Icons>
                </div>
            </Steps>
            <Heading isCentered>
                <Title as="h4" size="5" primary>
                    {langJson["final1"]}
                    <br />
                    {langJson["final2"]}
                </Title>
            </Heading>
            <ButtonsExtended isCentered>
                <InternalLink to={brandSlug ? "/brand" : "/"}>
                    <Button primary>{langJson["final3"]}</Button>
                </InternalLink>
                <InternalLink to={"/claims"}>
                    <Button primary>{langJson["final4"]}</Button>
                </InternalLink>
            </ButtonsExtended>
        </Content>
    );
}

export default Final;
