import React from "react";
import Helmet from "react-helmet";
import { Breadcrumb } from "../../sdk/Elements";
import { LangConsumer } from "../../context";
import InternalLink from "../InternalLink";

function Breadcrums({ crums, className }) {
    return (
        <LangConsumer>
            {({ lang }) => (
                <Breadcrumb className={className}>
                    <Helmet>
                        <script type="application/ld+json">
                            {`{
					      "@context": "https://schema.org",
					      "@type": "BreadcrumbList",
					      "itemListElement": [ 
		      				{
						        "@type": "ListItem",
						        "position": 1,
						        "name": "Home",
						        "item": "https://www.noritex.com/${lang}/"
						      },

					      ${
                              crums &&
                              crums.map((index, i) => {
                                  if (index.to == null) return null;
                                  return i > 1
                                      ? ""
                                      : "" +
                                            `{
									        "@type": "ListItem",
									        "position": ${i + 2},
									        "name": "${index.title}",
									        "item": "https://www.noritex.com/${lang}${
                                                index.to ? index.to : index.tags
                                            }"
									      }`;
                              })
                          }]
					    }`}
                        </script>
                    </Helmet>
                    <ul>
                        <li>
                            <InternalLink to="/home-login">Home</InternalLink>
                        </li>
                        {crums &&
                            crums.map((index, i) => {
                                if (index.tags != null) {
                                    if (index.tags.length <= 0) return;
                                    if (index.tags === "") return;
                                    return (
                                        <li
                                            key={`breadcrum-${index.tags}`}
                                            className="is-active"
                                        >
                                            <a>{index.tags}</a>
                                        </li>
                                    );
                                }
                                return (
                                    <li key={`breadcrum-${index.title}`}>
                                        <InternalLink to={index.to}>
                                            {index.title}
                                        </InternalLink>
                                    </li>
                                );
                            })}
                    </ul>
                </Breadcrumb>
            )}
        </LangConsumer>
    );
}

export default React.memo(Breadcrums);
