import { CountryRegionData } from "react-country-region-selector";
import Payment from "payment";
import validate from "../../utils/validator";

export const getCardIssuer = (number) => {
	const issuer = Payment.fns.cardType(number);
	return issuer === "dinersclub" ? "DINERS" : issuer.toUpperCase();
}

export const getCountriesIsoCode = (countries) => {
	return countries.map(country => {
		return CountryRegionData.filter(index => {
			return index[0] === country;
		})[0][1]
	});
}

export const getCountryIsoCode = (country) => {
	return CountryRegionData.filter(index => {
		return index[0] === country;
	})[0][1]
}

export function doFieldChange(e) {
	const { name, value } = e.target;
	const { formControls } = this.state;

	const updatedControls = {
		...formControls,
	};
	const updatedFormElement = {
		...updatedControls[name],
	};

	updatedFormElement.value = value;
	updatedFormElement.valid = validate(
		value,
		updatedFormElement.validationRules,
	);
	updatedFormElement.touched = true;

	updatedControls[name] = updatedFormElement;

	this.setState({ formControls: updatedControls });
};

export const isFieldValid = (field) => {
	if (field.valid && field.touched) return true;
	if (!field.valid && field.touched) return false;
	return true;
}

export function populateFormFromStorage(storageKey) {
	if (typeof localStorage !== "undefined") {
		const order = localStorage.getItem(storageKey);
		if (!order) return;

		const {shippingAddress} = JSON.parse(order);
		const updatedControls = {...this.state.formControls};

		if (!shippingAddress) return;

		Object.keys(shippingAddress).forEach(key => {
			const updatedElement = {...updatedControls[key]};
			updatedElement.value = shippingAddress[key];
			updatedElement.valid = true;
			updatedElement.touched = true;
			updatedControls[key] = updatedElement
		});

		this.setState({formControls: updatedControls});
	}
}

export const validateFormFields = (formControls, excludedFields = []) => {
	let hasErrors = false;
	const formControlsCopy = { ...formControls };
	const formData = {};

	// Check if all fields are valid
	Object.keys(formControlsCopy).filter(field => !excludedFields.includes(field))
		.forEach(fieldId => {
			const value = formControlsCopy[fieldId];
			if (!value.valid) {
				hasErrors = true;
				formControlsCopy[fieldId].touched = true;
			}
			formData[fieldId] = formControlsCopy[fieldId].value
		});

	return {hasErrors, formControls: formControlsCopy, formData};
}