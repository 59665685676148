import React, { useState } from "react";
import { Container, Section, Title, Heading } from "../../../sdk/Layout";
import { Button } from "../../../sdk/Button";
import { Content, ButtonsExtended } from "../style";
import Blueberry from "../../../../blueberry";
import { Preloader } from "../../../sdk/Elements";

function Explanation({ handleNextStep, langJson, brandSlug }) {
    const [claimText, setClaimText] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        Blueberry.claimText({ brandSlug })
            .then((resp) => {
                setClaimText(resp);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, []);

    return (
        <Content>
            <p>{langJson.support}</p>
            <Title as="h3" size="6">
                {langJson.support2}
            </Title>
            <div dangerouslySetInnerHTML={{ __html: claimText }} />
            {isLoading ? (
                <ul>
                    <li>
                        <Preloader half />
                    </li>
                    <li>
                        <Preloader />
                    </li>
                    <li>
                        <Preloader half />
                    </li>
                    <li>
                        <Preloader />
                    </li>
                    <li>
                        <Preloader half />
                    </li>
                    <li>
                        <Preloader />
                    </li>
                </ul>
            ) : (
                <ButtonsExtended isCentered>
                    <Button primary onClick={handleNextStep}>
                        {langJson["beginClaim"]}
                    </Button>
                </ButtonsExtended>
            )}
        </Content>
    );
}

export default React.memo(Explanation);
