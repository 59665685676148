import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Row, Subtitle } from "../../sdk/Layout";
import { Button, Buttons } from "../../sdk/Button";
import { Control, Field, FieldBody } from "../../sdk/Form";
import Icon from "../../components/Icons2";
import Blueberry from "../../../blueberry";
import ErrorMessage from "../../components/ErrorMessage";
import useNotification from "../../hooks/useNotification";

const ButtonGroup = styled(Buttons)`
    padding-top: 50px;
    text-align: right;
`;

function Seguridad({ _t }) {
    const [isFormEdited, setFormEdited] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [sucess, setSucess] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState("");
    const [oldPassword, setOldPassword] = React.useState("");

    const [handleNotify, NotifyProfile] = useNotification({
        timeout: 2000,
    });

    const onChangeOldPassword = (e) => {
        const { value } = e.target;
        setOldPassword(value);
    };

    const onChangeNewPassword = (e) => {
        const { value } = e.target;
        setNewPassword(value);
    };

    const onSave = () => {
        setIsLoading(true);
        setMessage(null);
        Blueberry.changePassword({
            oldPassword,
            password: newPassword,
        })
            .then(() => {
                setIsLoading(false);
                setNewPassword("");
                setOldPassword("");
                setMessage("Password Changed");
                setSucess(true);
                handleNotify({
                    paragraph: _t("profileUpdated"),
                    info: true,
                });
            })
            .catch((err) => {
                setMessage(err.reason);
                setIsLoading(false);
                setSucess(false);
                handleNotify({
                    paragraph: err.reason,
                    error: true,
                });
            });
    };

    React.useEffect(() => {
        if (newPassword !== "" && oldPassword !== "") {
            setFormEdited(false);
        }
    }, [newPassword, oldPassword]);

    return (
        <>
            <Helmet>
                <title>{_t("security")} - Noritex</title>
            </Helmet>
            <Subtitle as="h2" size="5">
                <Icon icon="lock" />
                {_t("security")}
            </Subtitle>
            <NotifyProfile />

            <Row className="content-row">
                <Field isHorizontal>
                    <FieldBody>
                        <Field>
                            <label htmlFor="oldPassword">
                                {_t("currentPassword")}
                            </label>
                            <Control isExpanded>
                                <input
                                    type="password"
                                    name="oldPassword"
                                    id="oldPassword"
                                    value={oldPassword}
                                    onChange={onChangeOldPassword}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <label htmlFor="newPassword">
                                {_t("newPassword")}
                            </label>
                            <Control isExpanded>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={onChangeNewPassword}
                                />
                            </Control>
                        </Field>
                    </FieldBody>
                </Field>
            </Row>
            <ButtonGroup>
                <Button
                    onClick={onSave}
                    primary
                    loading={isLoading}
                    static={isFormEdited}
                >
                    {_t("saveChanges")}
                </Button>
            </ButtonGroup>
        </>
    );
}

export default Seguridad;
