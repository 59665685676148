import React from "react";
import { CheckBoxExtended, Product } from '../style';
import NoImage from "../../../components/NoImage";

function ProductRow({item, addNewRow}) {
  return (
    <Product>
      <div className="checkbox">
        <CheckBoxExtended>
          <input type="checkbox" defaultChecked={false} onChange={addNewRow} />
          <span></span>
          <p></p>
        </CheckBoxExtended>
      </div>
      <div className="img" style={{paddingRight: 10}}>
        {
          (item.product?.imagesUrl) ?
            <img
              src={item.product.imagesUrl[0].thumb}
              alt=""
            /> 
            :
            <NoImage width={50} height={50} /> 
        }
      </div>
      <div className="text">
        <h3 className="product-title">{item.product?.title || item.product?.title_eng || "---"}</h3>
        <div className="product-details">
          <span>Precio: ${item.price.toFixed(2)}</span>
          <span>Items: {item.quantity}</span>
          <span>Ref: {item.slug}</span>
          <span>Total: ${(item.price * item.quantity).toFixed(2)}</span>
        </div>
      </div>
    </Product>
  );
}

export default ProductRow;
