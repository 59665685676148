import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { Row, Subtitle } from "../../sdk/Layout";
import { Control, Field, FieldBody, SelectBox } from "../../sdk/Form";
import Icon from "../../components/Icons2";
import validate from "../../utils/validator";
import Blueberry from "../../../blueberry";
import { Button, Buttons } from "../../sdk/Button";
import useNotification from "../../hooks/useNotification";

const ButtonGroup = styled(Buttons)`
    text-align: right;
`;

function Tienda({ user, _t }) {
    const [isFormEdited, setFormEdited] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formControl, setFormControl] = React.useState({
        companyName: {
            value: user && user.profile ? user.profile.companyName : "",
            valid:
                user && user.profile && user.profile.companyName ? true : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
        clientMarketSegment: {
            value: user.clientMarketSegment,
            valid: user.clientMarketSegment ? true : false,
            touched: false,
            validationRules: {
                minLength: 3,
                isRequired: true,
            },
        },
    });

    const [handleNotify, NotifyProfile] = useNotification({
        timeout: 2000,
    });

    const onUpdateProfile = () => {
        let formData = {};
        let hasErrors = false;
        const updatedFormElement = { ...formControl };

        for (let formElementId in formControl) {
            if (!formControl[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControl[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        console.log(true, formData);
        Blueberry.storeUpdate(formData).then((resp) => {
            setIsLoading(false);
            handleNotify({
                paragraph: _t("profileUpdated"),
                info: true,
            });
        });
    };

    const setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...formControl,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        setFormControl(updatedControls);
        setFormEdited(false);
    };

    const validateForm = (formElement) => {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    };

    return (
        <>
            <Helmet>
                <title>{_t("store")}</title>
            </Helmet>
            <NotifyProfile />
            <Subtitle as="h2" size="5">
                <Icon icon="store" />
                {_t("store")}
            </Subtitle>

            <Row>
                <Field isHorizontal>
                    <FieldBody isFlexEnd>
                        <Field>
                            <label>{_t("nameOfCompany")}</label>
                            <Control isExpanded>
                                <input
                                    type="text"
                                    name="companyName"
                                    onChange={setValue}
                                    className={validateForm(
                                        formControl.companyName
                                    )}
                                    value={formControl.companyName.value}
                                />
                            </Control>
                        </Field>
                        <Field>
                            <label>{_t("typeOfBusiness")}</label>
                            <Control isExpanded>
                                <SelectBox isFullWidth>
                                    <select
                                        type="text"
                                        name="clientMarketSegment"
                                        onChange={setValue}
                                        className={validateForm(
                                            formControl.clientMarketSegment
                                        )}
                                        value={
                                            formControl.clientMarketSegment
                                                .value
                                        }
                                    >
                                        <option value="">---Select---</option>
                                        <option value="homeDecorStore">
                                            {_t("homeDecorStore")}
                                        </option>
                                        <option value="wholesaler">
                                            Wholesaler (Mayorista)
                                            {_t("wholesaler")}
                                        </option>
                                        <option value="furnitureStore">
                                            {_t("furnitureStore")}
                                        </option>
                                        <option value="farmacies">
                                            {_t("farmacies")}
                                        </option>
                                        <option value="smallDetailers">
                                            {_t("smallDetailers")}
                                        </option>
                                        <option value="superMarket">
                                            {_t("superMarket")}
                                        </option>
                                        <option value="hardwareStore">
                                            {_t("hardwareStore")}
                                        </option>
                                        <option value="departmentStore">
                                            {_t("departmentStore")}
                                        </option>
                                        <option value="bookStores">
                                            {_t("bookStore")}
                                        </option>
                                        <option value="neighborhoodStore">
                                            {_t("entrepreneurship")}
                                        </option>
                                        <option value="other">
                                            {_t("other")}
                                        </option>
                                    </select>
                                </SelectBox>
                            </Control>
                        </Field>
                        <Field>    
                            <ButtonGroup>
                                <Button
                                    onClick={onUpdateProfile}
                                    primary
                                    loading={isLoading}
                                    static={isFormEdited}
                                >
                                    {_t("saveChanges")}
                                </Button>
                            </ButtonGroup>
                        </Field>
                    </FieldBody>
                </Field>
            </Row>
        </>
    );
}

export default Tienda;
